import { Box, Button, Grid, Typography } from "@mui/material"
import React from "react"
import OTPInput from "react-otp-input"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, NetWorkCallMethods, refreshAPI } from "../../../utils"
import { useStyles } from "../style"
import jwt_decode from "jwt-decode";


const OTPScreen = ({ loginData = "", setIsVerifyOTP = () => false, updateState = () => false }) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext);
    const onVerifyOTP = () => {
        const payload = {
            "mobile_no": loginData?.mobile_no?.mobile ?? null,
            "mobile_country_code": (loginData?.mobile_no?.mobile_code).slice(1) ?? null,
            "otp": (loginData?.otp) ?? "0000",
            "is_register":loginData?.is_register??false
        }
        NetworkCall(
            `${config.api_url}/user/verify_otp`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            setIsVerifyOTP(false)
            if (res?.data?.user_details) {
                localStorage.setItem("refresh_token", res?.data?.user_details?.refresh_token)
                localStorage.setItem("auth_token", res?.data?.user_details?.auth_token)
                const token = jwt_decode(res?.data?.user_details?.auth_token)
                localStorage.setItem("user_name", token?.username)
                localStorage.setItem("user_id", token?.user_id)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg:"OTP Verified Successfully",
                });
            }
            updateState(null, null, true)
            refreshAPI()
            // localStorage.setItem("is_myForest", false)
            // localStorage.setItem("is_plant_sapling", false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Logged In Successfully.",
            });
        }).catch((err) => {

        })
    }
    const ResendOTP = () => {
        const payload = {
            "mobile_no": loginData?.mobile_no?.mobile ?? null,
            "mobile_country_code": (loginData?.mobile_no?.mobile_code).slice(1) ?? null,
        }
        NetworkCall(
            `${config.api_url}/user/resend_otp`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "OTP Sent Successfully.",
            });
        }).catch((err) => {

        })
    }
    return (
        <Box p={2}>
            <Typography className={classes.otpTitle}>Enter OTP</Typography>
            <OTPInput
                inputType="number"
                shouldAutoFocus={true}
                className={classes.otp}
                value={loginData?.otp}
                onChange={(val) => updateState("otp", val)}
                numInputs={6}
                separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                    border: "1px solid #E4E8EE",
                    borderRadius: "8px",
                    width: "54px",
                    height: "54px",
                    fontSize: "14px",
                    color: "#000",
                    fontFamily: "crayond_medium",
                    margin: 4
                }}
                focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none"
                }}
            />
            <Grid container spacing={2} alignItems="center" className={classes.bottomFixBtn}>
                <Grid item xs={6}>
                        <Button variant="outlined" fullWidth className={classes.outlineBtn} onClick={() => ResendOTP()} >Resend OTP</Button>
                </Grid>
                <Grid item xs={6}>
                        <Button variant="contained" fullWidth className={classes.saveBtn} onClick={() => onVerifyOTP()} disabled={loginData?.otp?.length === 6 ? false : true}>Verify OTP</Button>
                </Grid>
            </Grid>
        </Box>
    )
}
export default OTPScreen