
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    imageBox: {
        backgroundColor: "#ffffff",
        border: '1px solid #E4E8EE',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        height: "300px",
        width: "100%",
    },
    hoverImageBox: {
        // opacity: "12",
        // backgroundColor: '#0000009c',
        position: 'absolute',
        width: "100%",
        borderRadius: 4,
        top: 1,
        bottom: 0,
        right: 0,
        display: "flex",
        justifyContent: 'end',
        padding: "16px",
        left: 0,
        height: 300,
    },
    editbox: {
        border:"1px solid #E4E8EE",
        display: 'flex',
        justifyContent: 'center',
        padding: "16px",
        borderRadius: "50%",
        height: 20,
        width: 20,
        alignItems: 'center',
        cursor: 'pointer'

    },
    saplingImg: {
        borderRadius: "20px",
        width: '100%',
        objectFit: 'cover',
    },
    saveBtn: {
        backgroundColor: '#71BC44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 40,
        "&:hover": {
            backgroundColor: '#71BC44',
        }

    },
    bottomFixBtn: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: "16px"
        // top:0
    },
    container: {
        width: "100%",
        height: "150px",
        borderRadius: "4px",
    },
    uploadContainer: {
        width: "100%",
        height: "300px",
        borderRadius: "20px",
        border: "2px dashed #95e366",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
    },
    uploadInput: {
        padding: "0px",
        position: "absolute",
        cursor: "pointer",
        height: "100%",
        width: "100%",
        opacity: 0,
    },
    uplaodTitle: {
      fontSize: "0.75rem",
      color: "#71BC44",
      fontFamily: "crayond_bold",
      marginTop: "6px",
    },
    petnameBox:{
        position:'absolute',
        bottom:'-20px',
        // left:'50%',
        // translate: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor:'transparent'

    }
}
))