import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
// import { LocalStorageKeys } from '../../../utils';
import Logo from '../../../assets/logo.png';
import { AsyncPaginateComponenet } from '../../asyncPaginate';
import { useApolloClient } from "@apollo/client";
import { GET_SEARCH_LIST } from '../../../graphql/queries';
import { SearchIcon } from '../../svg';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router-dom";
import { CustomSwitch } from '../../customSwitch';
import { Typography, useMediaQuery } from '@mui/material';
import ForestIcon from '@mui/icons-material/Forest';
import ParkIcon from '@mui/icons-material/Park';

const useStyles = makeStyles((theme) => ({
    grow: {
        // flexGrow: 0.41,
        // zIndex: theme.zIndex.drawer + 1,

        [theme.breakpoints.down(600)]: {
            flexGrow: 1
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#fff",
        '& .MuiToolbar-root': {
            // flexWrap: "wrap",
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            gap: "10px",
            padding: "10px"
        }
    },
    customAppBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#fff",
        '& .MuiToolbar-root': {
            // flexWrap: "wrap",
            gap: "4px",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
        }
    },
    title: {
        display: 'block',
        color: "#000"
    },
    titleContainer: {
        // marginLeft: theme.spacing(2)
    },
    menuIcon: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    switchLabel: {
        color: "#02111A",
        textAlign: "center",
        fontFamily: "crayond_regular",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.18px;",
    }
}));

export const TopNavBar = (props) => {

    const {
        searchValue,
        onChangeSearch,
        isMyForest = false,
        handleMyForest = () => false,
    } = props;

    const location = useLocation();

    const classes = useStyles();

    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [viewSearch, setViewSearchSearch] = useState(false);
    const [isContributeRoute, setIsContributeRoute] = useState(false);
    const matches = useMediaQuery('(min-width: 799px)')

    const loadOptions = async (search = "", array) => {
        setLoading(true);

        let result;

        result = await client.query({
            query: GET_SEARCH_LIST,
            variables: {
                search
            }
        }).then((res) => {
            let forestList = res.data.forest.nodes.map(_ => ({
                label: _?.forestName,
                value: {
                    lat: Number(_?.forestGeoLat),
                    lng: Number(_?.forestGeoLong),
                },
                findByNodeId: _.nodeId,
                lat: _?.forestGeoLat,
                lng: _?.forestGeoLong,
                ..._
            }));
            // let treeList = res.data.trees.nodes.map(_ => ({
            //     label: constructTreeName(_.forestTreeName, _?.forestByForestId?.forestName),
            //     value: _?.nodeId,
            //     findByNodeId: _?.forestByForestId?.nodeId,
            //     lat: _?.forestTreeGeoLat,
            //     lng: _?.forestTreeGeoLong,
            //     ..._
            // }));
            return [...forestList]
            // return [...forestList, ...treeList]
        }).catch((err) => {
            console.log(err)
        })

        setLoading(false);
        return {
            options: result,
            // hasMore: (array?.length + result?.contact?.length) < result?.count[0]?.count
            hasMore: false
        }

    }

    const clickIcon = () => {
        // if(viewSearch){
        setViewSearchSearch(!viewSearch)
        // }
    }

    useEffect(() => {

        const isInContributionRoute = location.pathname.includes('/contribution');

        if (isInContributionRoute) {
            setIsContributeRoute(true);
        } else {
            setIsContributeRoute(false);
        }
    }, [location?.pathname])

    return (
        <div className={classes.grow}>
            <AppBar position="static" className={matches ? classes.appBar : classes.customAppBar}>
                <Toolbar {...(isContributeRoute === true ? { sx: { display: 'flex', justifyContent: 'center' } } : {})}>
                    {(isContributeRoute === false) && <>
                        {/* <IconButton className={classes.menuIcon} onClick={toogleSideNavBar} size="large">
                        <MenuIcon htmlColor="white" />
                    </IconButton> */}

                        {
                            (!viewSearch) &&
                            <>
                                {/* <Stack direction="row" alignItems="center" gap={"10px"}> */}
                                <img src={Logo} alt="logo" style={{ height: 44 }} />
                                {/* </Stack> */}
                                {/* <Box sx={[
                                    {
                                        "@media (max-width:327px)": {
                                            display: "none"
                                        }
                                    }
                                ]}
                                    className={classes.grow} /> */}
                            </>
                        }
                        {!isMyForest &&
                            <Box
                                sx={[{
                                    minWidth: "300px",
                                    "@media (max-width:600px)": {
                                        display: "none"
                                    },
                                }]}
                            >
                                {
                                    <AsyncPaginateComponenet
                                        isPaginate
                                        loadOptions={(search, array) => loadOptions(search, array)}
                                        loading={loading}
                                        debounceTimeout={800}
                                        value={searchValue}
                                        onChange={(value) => {
                                            onChangeSearch(value);
                                        }}
                                        placeholder="Search"
                                    />}
                            </Box>
                        }
                        {<Box
                            sx={[{
                                "@media (max-width:799px)": {
                                    // display: "none"
                                },
                            }]}>
                            <Stack
                                direction={"row"} columnGap={"8px"}
                                justifyContent={"center"} alignItems={"center"}>
                                {matches ?
                                    <Typography className={classes.switchLabel}>{"All Forest"}</Typography>
                                    :
                                    <ForestIcon sx={{ color: '#56B447' }} />}
                                <CustomSwitch
                                    checked={isMyForest}
                                    onChange={(event) => {
                                        handleMyForest(event?.target?.checked)
                                    }} />
                                {matches ?
                                    <Typography className={classes.switchLabel}>{"My Forest"}</Typography>
                                    : <ParkIcon sx={{ color: '#56B447' }} />}
                            </Stack>
                        </Box>}
                    </>}
                    {!isMyForest &&
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={"10px"}
                            sx={[{
                                display: "none",
                                "@media (max-width:600px)": {
                                    flex: viewSearch ? 1 : 0,
                                    display: "flex"
                                },
                            }]}
                        >
                            {
                                viewSearch &&
                                <AsyncPaginateComponenet
                                    isPaginate
                                    loadOptions={(search, array) => loadOptions(search, array)}
                                    loading={loading}
                                    debounceTimeout={800}
                                    value={searchValue}
                                    onChange={(value) => {
                                        onChangeSearch(value);
                                    }}
                                    placeholder="Search"
                                />}
                            <IconButton
                                sx={{
                                    backgroundColor: "#1100002b",
                                }}
                                onClick={() => clickIcon()}
                            >
                                {
                                    viewSearch ? (<CloseIcon />) : (<SearchIcon />)
                                }
                            </IconButton>
                        </Stack>
                    }
                    {isContributeRoute === true && <>
                        <Stack direction="row" alignItems="center" justifyContent="center" gap={"10px"}>
                            <img src={Logo} alt="logo" style={{ height: 44 }} />
                        </Stack>
                    </>}
                </Toolbar>
            </AppBar>
        </div>
    );
}
