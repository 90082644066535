


export const DialogDrawer_Style = {
    dialog: {
        width: "100%",
        maxWidth: "auto",
        padding: "0 !important",
        "& .MuiPaper-root": {
            width: "100% !important",
            borderRadius: "4px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",

        },
        "& .MuiDialogContent-root": {
            padding: "0px !important",
            position: 'relative'
        },
        '& .MuiDialog-container': {
            padding: (props) => props?.padding ?? '',

        },
        "& .MuiDialogTitle-root":{
            fontSize:"16px"
        }
    },
    header: {
        border: "1px solid #E4E8EE",
        fontSize:"16px",
        fontFamily: 'crayond_bold',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // backgroundColor:"#F2F4F7"
    },
    drawer: {
        "& .MuiDrawer-paper": {
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "center",
            height: "100%",


        },
        "& .MuiContainer-root": {
            padding: "0px",
        },
        "& .MuiDialogTitle-root":{
            padding:"16px"
        }
    },
    component: {
        overflow: "auto",
        // height: (props)=>props?.height ?? "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    footer: {
        position: 'sticky',
        width: "100%",
        bottom: "0",
    },

}
