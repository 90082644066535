import React, { useContext, useEffect, useRef, useState } from "react";
import { SEO } from "../../components";
import { useApolloClient } from "@apollo/client";
import {
  GET_ALL_FORESTS_AND_TREES,
  GET_FOREST_DETAILS_BY_ID,
  GET_FOREST_SEO_BY_UNIQUE_ID,
  GET_MY_FOREST,
  GET_TREE_SEO_BY_FOREST_ID,
  GET_TREES_BY_NODEID,
} from "../../graphql/queries";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Popover from "@mui/material/Popover";
import styled from "@mui/material/styles/styled";

import {
  ContributionDetails,
  ContributionCertificateScreen,
  Contribution,
} from "../../screens";
import { Routes } from "../../router/routes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { InfoDetails } from "../../components/map/infoDetails";
import { DialogContext } from "../../contexts";
import TreeInfo from "../../components/map/treeInfo";
import CustomForestMap from "../../components/customForestMap/customForestMap";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { DialogDrawer } from "../../components/dialogDrawer";
import { StoreCard } from "../../components/saplingStore/card";
import { PlantSaplingForm } from "../../components/plantSaplingForm/form";
import InfiniteScroll from "react-infinite-scroll-component";
import { CustomCamera } from "../../components/customCamera";
import moment from "moment";
import html2canvas from "html2canvas";
import NewLoginScreen from "../login_new";
import OTPScreen from "../login_new/components/otp";
import RegisterScreen from "../login_new/components/register";
import { useStyles } from "./styles";
import { differenceInDays } from "date-fns";
// import { CustomCamera } from "../../components/customCamera";

const ContributionPopUp = styled(Popover)({});

const initialFormData = {
  "rawImage": "",
  "displayImage": "",
  "species": "",
  "petname": "",
  "latitude": "12.722917",
  "longitude": "80.001556",
  "height": "",
  "diameter": "",
  "age": "",
  planted_on: new Date(),
  error: {
    species: "",
    rawImage: "",
    petname: ""
  }
}

export const Home = (props) => {
  const google_api_key = "AIzaSyC460AqqU6PgMO4s5wJvE5GLge9evj4r6o";
  const client = useApolloClient();
  const dialog = useContext(DialogContext);

  const [state, setState] = useState({
    allForestDetails: [],
    forestList: [],
    initialCenter: "",
  });

  const history = useHistory();
  let path = useParams();

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [letsPlant, setLetsPlant] = React.useState(false);
  const [proceedPay, setProceedPay] = React.useState(false);
  const [isStoreListLoading, setIsStoreListLoading] = React.useState(true);
  const [timelineOrder, setTimelineOrder] = useState({ label: "", value: '' });
  let isMyForest = JSON.parse(localStorage.getItem("is_myForest"))

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [seoData, setSeoData] = useState({
    title: "Be The Tree Hugger",
    description:
      "Be The Tree Hugger, is your go-to hub for eco-conscious living. Discover practical tips, inspiring content, and a community dedicated to preserving our planet. Join us in our mission to cultivate a greener, more sustainable world.",
    type: "website",
    imageUrl: `${window.origin}/images/bth_og_mirchi.jpg`,
  });

  const location = useLocation();

  const [storeOpen, setStoreOpen] = React.useState(false)
  const [isFormOpen, setIsFormOpen] = React.useState(false)
  const [storeData, setStoreData] = React.useState([])
  const [offset, setOffset] = React.useState(0)
  const [searchText, setSearchText] = React.useState("")
  const [formData, setFormData] = React.useState(initialFormData)
  const [showLogin, setShowLogin] = React.useState(false)
  const [isVerifyOTP, setIsVerifyOTP] = React.useState(false)
  const [isRegister, setIsRegister] = React.useState(false)
  const [loginData, setLoginData] = React.useState({
    mobile_no: "",
    otp: '',
    first_name: '',
    last_name: ""
  })
  const classes = useStyles()
  const updateLoginState = (k, v, is_initial = false) => {
    if (is_initial) {
      setLoginData({
        mobile_no: "",
        otp: '',
        first_name: '',
        last_name: ""
      })
    } else {
      if (k === "mobile_no") {
        setLoginData({
          ...loginData, [k]: {
            mobile: v,
            mobile_code: "+91"
          }
        })
      }
      else {
        setLoginData({ ...loginData, [k]: v })
      }
    }
  }
  const size = useWindowDimensions()
  const matches = useMediaQuery('(min-width: 768px)')
  const forest_id = "5f4325a6-6784-41df-a265-945abbb1fff6"
  const getStoreData = (offset = 0, search = "", forest_id = "5f4325a6-6784-41df-a265-945abbb1fff6", is_loadMoreData = false) => {
    let url = `/get-stores?offset=${offset}&limit=10&forest_id=${forest_id}`;
    if (currentLocation?.lat && currentLocation?.lng) {
      url = url + `&lat=${currentLocation?.lat}&lng=${currentLocation?.lng}`
    }
    NetworkCall(
      `${config.api_url}${url}`,
      NetWorkCallMethods.get, {}, null, true, false
    ).then((res) => {
      if (is_loadMoreData) {
        setStoreData([...storeData, ...res?.data?.data])
      } else {
        setStoreData(res?.data?.data)
      }
      setIsStoreListLoading(false);
    }).catch((err) => {
      console.log(err)
    })
  }
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getStoreData(offset + 10, searchText, forest_id, true);
  };
  const updateState = (k, v) => {
    let error = formData?.error
    error[k] = ""
    if (k === 'rawImage') {
      setFormData({ ...formData, [k]: v?.file, displayImage: v?.imageUrl, error })
    } else {
      setFormData({ ...formData, [k]: v, error })
    }
  }
  const validateForm = () => {
    let isValid = true;
    let error = formData.error;
    if (formData?.species?.length === 0) {
      isValid = false;
      error.species = "Species Name is required";
    }
    if (formData?.rawImage?.length === 0) {
      isValid = false;
      error.rawImage = "Image is required";
    }
    if (formData?.petname?.length === 0) {
      isValid = false;
      error.petname = "Pet Name is required";
    }
    setFormData({ ...formData, error })
    return isValid
  }
  const handleSubmitForm = () => {
    if (validateForm()) {
      const payload = new FormData();
      payload.append("forest_id", "5f4325a6-6784-41df-a265-945abbb1fff6");
      payload.append("species_id", formData?.species?.value);
      payload.append("forest_tree_petname", formData?.petname);
      payload.append("forest_tree_name", localStorage.getItem("user_name") + " " + formData?.species?.label);
      payload.append("latitude", currentLocation?.lat?.toString());
      payload.append("longitude", currentLocation?.lng?.toString());
      payload.append("address", address);
      payload.append("planted_on", moment(formData?.planted_on).format("YYYY-MM-DD"));
      payload.append("tree_assets", formData?.rawImage);
      NetworkCall(
        `${config.api_url}/tree/plant_the_tree`,
        NetWorkCallMethods.post, payload, null, true, false
      ).then((res) => {
        setIsPlantSaplingSuccess(true);
        setTimeout(() => {
          setIsPlantSaplingSuccess(false);
          setIsPlantSaplingView(true);
        }, 3000);
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      localStorage.setItem(LocalStorageKeys.device, "mobile")
    } else {
      localStorage.setItem(LocalStorageKeys.device, "web")
    }
    getAllForests();
    getSeoDetails();
    // eslint-disable-next-line
  }, []);

  const getAllForests = async (is_my_forest) => {
    await client
      .query({
        query: is_my_forest ? GET_MY_FOREST : GET_ALL_FORESTS_AND_TREES,
        fetchPolicy: "network-only",
        variables: { user_id: localStorage.getItem("user_id") }
      })
      .then((res) => {
        const tempList = res?.data?.allForests?.nodes;

        let finalList = tempList?.map?.(forest => {
          let finalClusters = [];

          if (forest?.forestBoxesByForestId?.nodes?.length > 0) {
            finalClusters = forest?.forestBoxesByForestId?.nodes?.map?.(box => {
              return {
                id: box?.id,
                nodeId: box?.nodeId,
                boxUniqueId: box?.uniqueId,
                boundaries: JSON.parse(box?.boxBoundary),
                treesCount: box?.forestTreesByBoxId?.totalCount,
                clusterTrees: box?.forestTreesByBoxId?.nodes?.map?.(tree => {
                  return {
                    id: tree?.id,
                    nodeId: tree?.nodeId,
                    treeUniqueId: tree?.treeUniqueId,
                    forestTreeName: tree?.forestTreeName,
                    createdBy: tree?.createdBy,
                    forestTreeGeoLat: Number(tree?.forestTreeGeoLat),
                    forestTreeGeoLong: Number(tree?.forestTreeGeoLong),
                    plantSpecies: {
                      id: tree?.masterPlantspecyByMasterPlantSpeciesId?.id,
                      speciesName: tree?.masterPlantspecyByMasterPlantSpeciesId?.speciesName,
                      commonName: tree?.masterPlantspecyByMasterPlantSpeciesId?.commonName,
                    }
                  }
                }),
              }
            })
          } else if (forest?.forestTreesByForestId?.nodes?.length > 0) {
            const id = crypto?.randomUUID();
            const boxUniqueId = crypto?.randomUUID();
            let nodeId = btoa(JSON?.stringify(["forest_box", id]));
            let clusterTrees = [];

            if (forest?.forestTreesByForestId?.nodes?.length > 0) {
              clusterTrees = forest?.forestTreesByForestId?.nodes?.map?.(tree => {
                return {
                  id: tree?.id,
                  nodeId: tree?.nodeId,
                  treeUniqueId: tree?.treeUniqueId,
                  forestTreeName: tree?.forestTreeName,
                  createdBy: tree?.createdBy,
                  forestTreeGeoLat: Number(tree?.forestTreeGeoLat),
                  forestTreeGeoLong: Number(tree?.forestTreeGeoLong),
                  plantSpecies: {
                    id: tree?.masterPlantspecyByMasterPlantSpeciesId?.id,
                    speciesName: tree?.masterPlantspecyByMasterPlantSpeciesId?.speciesName,
                    commonName: tree?.masterPlantspecyByMasterPlantSpeciesId?.commonName,
                  }
                }
              })
            }

            finalClusters = [{
              id,
              nodeId: nodeId,
              boxUniqueId,
              boundaries: JSON.parse(forest?.forestBoundary),
              treesCount: forest?.forestTreesByForestId?.totalCount,
              clusterTrees,
            }]
          }

          return {
            id: forest?.id,
            nodeId: forest?.nodeId,
            forestUniqueId: forest?.forestUniqueId,
            forestName: forest?.forestName,
            forestLogo: forest?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorForestLogo,
            center: {
              lat: Number(forest?.forestGeoLat),
              lng: Number(forest?.forestGeoLong),
            },
            boundaries: JSON.parse(forest?.forestBoundary),
            clusters: finalClusters
          }
        })

        props?.onChangeSearch({
          label: finalList?.[0]?.forestName,
          value: finalList?.[0]?.center,
        });

        setState({
          allForestDetails: tempList,
          forestList: finalList,
          initialCenter: finalList?.[0]?.center,
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSeoDetails = () => {
    let temp = location.pathname.split("/").filter(Boolean);
    let type, unique_id, foerst_unique_id;
    if (location.pathname.includes("forest")) {
      type = temp[0];
      unique_id = temp[1];
    } else {
      type = temp[0];
      foerst_unique_id = temp[1];
      unique_id = temp[2];
    }
    if (!type || !unique_id || !foerst_unique_id) return;
    let query = GET_FOREST_SEO_BY_UNIQUE_ID;
    if (type === "tree") {
      query = GET_TREE_SEO_BY_FOREST_ID;
    }
    client
      .query({
        query,
        variables: {
          unique_id,
          foerst_unique_id,
        },
      })
      .then(({ data }) => {
        const getTitle = ({ name, forest, sponsors }) => {
          if (type !== "tree") {
            return `${name}, sponsored by ${sponsors?.nodes
              .map((i) => i.sponsorBySponsorId.sponsorName)
              .join(",")}`;
          }
          return `${name} tree planted in ${forest?.name
            }, sponsored by ${sponsors?.nodes
              .map((i) => i?.sponsorBySponsorId?.sponsorName)
              .join(",")}`;
        };
        const getDescription = ({
          total_trees,
          total_species_planted,
          oxygen_produced,
          carbon_offset,
          species,
        }) => {
          if (type !== "tree") {
            return `Rich treasure with ${total_trees} trees, ${total_species_planted} species, generated ${Number(oxygen_produced) / 1000
              } kt of oxygen and has offset ${Number(carbon_offset) / 1000
              } kt of carbon.`;
          }
          return `Belonging to ${species?.name} species, generated ${Number(oxygen_produced) / 1000
            } kt of oxygen and has offset ${Number(carbon_offset) / 1000
            } kt of carbon.`;
        };
        let seoData = data.seoData;
        if (type === "tree") {
          seoData = data?.seoData?.nodes[0];
        }
        let {
          name,
          forest,
          total_trees,
          total_species_planted,
          oxygen_produced,
          carbon_offset,
          sponsors,
          species,
        } = seoData;
        setSeoData({
          ...seoData,
          title: getTitle({ name, forest, sponsors }),
          description: getDescription({
            total_trees,
            total_species_planted,
            oxygen_produced,
            carbon_offset,
            species,
          }),
          imageUrl: sponsors?.nodes?.length
            ? sponsors.nodes?.[0]?.sponsorBySponsorId?.sponsorOgImageUrl
            : `${window.origin}/images/bth_og_mirchi.jpg`,
        });
      })
      .catch(console.error);
  };

  const letsPlantFunc = () => {
    setAnchorEl(null);
    setLetsPlant(true);
  };

  const HandlePtoPCancel = () => {
    setAnchorEl(true);
    setLetsPlant(false);
  };

  const handleProcessAndPay = () => {
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
      setLetsPlant(false);
      setProceedPay(true);
    }, 2000);
  };

  const HandleBackToBTH = () => {
    setAnchorEl(null);
    setLetsPlant(false);
    setProceedPay(false);
    history.push(Routes.home);
  };

  const handleClick = (event) => {
    setAnchorEl(null);
    history.push(Routes.home);
  };

  useEffect(() => {
    const isInContributionRoute = location.pathname.includes("/contribution");

    if (isInContributionRoute) {
      setAnchorEl(true);
    } else {
      setAnchorEl(false);
    }
  }, [location?.pathname]);

  const modalStyle = {
    '& .MuiPaper-root.MuiDialog-paper': {
      minWidth: { sm: "90vw", md: '78vw' },
      minHeight: '94vh',
      borderRadius: '12px',
      padding: 0,
      overflowX: 'auto'
    },
    '& .MuiDialogContent-root': {
      padding: '0 !important',
      overflowX: 'auto',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      'scrollbarWidth': 'none',
      '-ms-overflow-style': 'none'
    }
  }

  const modalStyleForest = {
    '& .MuiDialogContent-root': {
      padding: '0 !important',
      overflowX: 'auto',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      'scrollbarWidth': 'none',
      '-ms-overflow-style': 'none'
    }
  }

  const dialogComponent = (component, type) => {
    dialog.setDialog({
      ...dialog,
      open: true,
      body: component,
      container: document.querySelector('#map'),
      sx: type === 'forest' ? modalStyleForest : modalStyle
    })
  }

  const closeDialog = () => {
    dialog.setDialog({
      open: false
    })
  }

  const handleForestClick = async (data) => {
    const { forestUniqueId: uniqId } = data;
    await client.query({
      query: GET_FOREST_DETAILS_BY_ID,
      fetchPolicy: "network-only",
      variables: {
        uniqId
      }
    }).then(res => {
      const forest = res?.data?.allForests?.nodes?.[0];
      const speciesCount = res?.data?.allForests?.nodes?.[0]?.totalSpeciesPlanted;
      const forestImages = res?.data?.allForests?.nodes?.[0]?.forestAssertsByForestId?.nodes;
      const construstForestURL = (url) => url?.split(',');
      if (forest) {
        dialogComponent(
          <InfoDetails
            uniqueId={uniqId}
            title={forest?.forestName}
            logo=""
            description={forest?.forestDesc ?? ""}
            showVideo={false}
            videos={forest?.forestUrl ? construstForestURL(forest?.forestUrl) : []}
            subTitle="Stats"
            info={[
              { label: 'Oxygen Generated', value: Math.round(forest?.forestOxygen / 1000).toLocaleString(), unit: "kt" },
              { label: 'Carbon Offset', value: Math.round(forest?.forestCarbonoffset / 1000).toLocaleString(), unit: "kt" },
              { label: 'Trees Planted', value: forest?.totalTrees },
              { label: 'Species Planted', value: speciesCount ? speciesCount : 0 },
            ]}
            images={forestImages}
            width={'304px'}
            onClose={closeDialog}
            sponsoredName={forest?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorName}
            sponsoredLogo={forest?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorLogo}
            share={true}
            url={data?.nodeId ? window.location.origin + "/forest/" + forest.forestUniqueId : window.location.href}
          />, 'forest'
        )
      } else {
        dialog.setDialog({
          open: false
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const handleLocationTree = () => { }

  const handleOrderTimeline = (e, val) => {
    setTimelineOrder(val)
  }

  const handleTreeClick = async (data) => {
    let uniqId = data?.treeUniqueId;
    await client.query({
      query: GET_TREES_BY_NODEID(),
      fetchPolicy: "network-only",
      variables: {
        uniqId
      }
    }).then(res => {
      const forestTree = res?.data?.allForestTrees?.nodes?.[0];
      const forestId = res?.data?.allForestTrees?.nodes[0]?.forestByForestId?.forestUniqueId
      if (forestTree) {
        dialogComponent(
          <TreeInfo
            treeName={forestTree?.forestTreeName}
            treeUniqueId={forestTree?.treeUniqueId}
            isAlive={forestTree?.isActive}
            treeHeight={forestTree?.forestTreeHeight}
            treeDia={forestTree?.forestTreeDia}
            treeAge={forestTree?.forestTreeAge}
            treeCorban={Math.round(forestTree?.forestTreeCarbonoffset * 100) / 100}
            treeOxygen={Math.round(forestTree?.forestTreeOxygen * 100) / 100}
            treeLat={Number(forestTree?.forestTreeGeoLat)}
            treeLong={Number(forestTree?.forestTreeGeoLong)}
            isGifted={forestTree?.giftForestPlantsByGiftTreeId?.nodes.length}
            gifterName={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.name}
            gifterUrl={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.userAssetUrl}
            giftDesignation={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.designation}
            giftedOrganizationName={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.organizationName}
            giftedOrgUrl={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.orgLogoUrl}
            giftedDate={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.updatedAt}
            timelineOrder={timelineOrder}
            timelineData={forestTree?.forestPlantTimelinesByPlantId?.nodes}
            url={path.tree_id && !data?.nodeId ? window.location.href : window.location.origin + "/tree/" + forestId + "/" + forestTree.treeUniqueId}
            handleOrderTimeline={handleOrderTimeline}
            handleLocationTree={handleLocationTree}
            handleCloseTreeInfo={closeDialog}
          />, 'tree')
      } else {
        dialog.setDialog({
          open: false
        })
      }
    }).catch(err => {
      console.log(err)
      dialog.setDialog({
        open: false
      })
    })
  }
  const handleOpenStoreList = () => {
    getStoreData();
    setStoreOpen(true);
  }
  const handlePlantSapling = () => {
    localStorage.setItem("is_plant_sapling", true)
    openLoginForm()
  }
  const openLoginForm = () => {
    setShowLogin(true)
  }
  const handleCloseStoreList = () => {
    setStoreOpen(false);
    setTimeout(() => {
      setOffset(0);
      setStoreData([]);
      setIsStoreListLoading(true);
    }, 800);
  }
  const handleOpenPlantSaplingForm = () => {
    if (localStorage.getItem("auth_token")) {
      setIsFormOpen(true);
    } else {
      setShowLogin(true)
    }
  }
  const handleClosePlantSaplingForm = () => {
    setIsFormOpen(false);
    setTimeout(() => {
      setIsPlantSaplingSuccess(false);
      setIsPlantSaplingView(false);
      setFormData({
        "rawImage": "",
        "displayImage": "",
        "species": "",
        "petname": "",
        "latitude": "12.722917",
        "longitude": "80.001556",
        "height": "",
        "diameter": "",
        "age": "",
        planted_on: new Date(),
        error: {
          species: "",
          rawImage: "",
          petname: ""
        }
      });
    }, 800);
  }

  const [currentLocation, setCurrentLocation] = useState({ lat: null, lng: null });
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  // Function to fetch current location
  const fetchLocation = () => {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by your browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
        fetchAddress(latitude, longitude);
      },
      (err) => {
        setError('Failed to fetch location. Please enable location services.');
      }
    );
  };
  // Function to fetch address from Google Maps API
  const fetchAddress = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${google_api_key}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data?.results && data?.results[0]) {
        setAddress(data.results[0].formatted_address);
      } else {
        setError('No address found for the provided location.');
      }
    } catch (err) {
      setError('Failed to fetch address. Please try again.');
    }
  };

  useEffect(() => {
    fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [isPlantSaplingSuccess, setIsPlantSaplingSuccess] = useState(false);
  const [isPlantSaplingView, setIsPlantSaplingView] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);



  //base 64 to normal file
  function urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }
  //share ticket
  const shareDetails = async (fileName) => {
    // const removedContent = document.querySelector("download")
    // removedContent.style.display = "none"
    // setDontShow(true)
    const node = document.getElementById("tree_details");
    // useCors to draw image from different origin
    html2canvas(node, { useCORS: true }).then(async (canvas) => {

      let base64File = canvas.toDataURL();
      let file2 = await urltoFile(base64File, `${fileName}.png`, "image/png")
      // setDontShow(false)
      let fileArray = [file2];
      if (navigator.canShare && navigator.canShare({ files: fileArray })) {
        try {
          await navigator
            .share({
              files: fileArray,
            })

          /*
            Show a message if the user shares something
          */
          console.log("Thanks for Sharing!");
        } catch (err) {
          /*
             This error will appear if the user cancels the action of sharing.
           */
          console.log(`${"Couldn't share"} ${err}`);
        }


      }

    })
  }
  useEffect(() => {
    const auth_token = localStorage.getItem("auth_token");

    if (isMyForest && !Boolean(auth_token?.length > 0)) {
      setShowLogin(true)
    } else if (isMyForest) {
      getAllForests(true)
    } else {
      getAllForests(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMyForest])

  const handleCameraOpen = (isOpen) => {
    if (localStorage.getItem("auth_token")) {
      setIsCameraOpen(isOpen);
    } else {
      setShowLogin(true)
    }
  }

  const imageRef = useRef(null); // Create a ref for the image element
  const [shareImageHeight, setShareImageHeight] = useState("100%");

  const handleImageLoad = () => {
    if (imageRef.current) {
      const renderedHeight = imageRef.current.offsetHeight;
      setShareImageHeight(`${renderedHeight}px`)
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SEO {...seoData} />
      <div
        style={{
          width: "100%",
          height: "100%",
        }}>
        {letsPlant !== true && proceedPay !== true && (
          <CustomForestMap
            size={size}
            forestDetails={state?.allForestDetails}
            searchValue={props?.searchValue}
            forestList={state?.forestList}
            initialCenter={state?.initialCenter}
            handleForestClick={handleForestClick}
            handleTreeClick={handleTreeClick}
            handleOpenStoreList={handleOpenStoreList}
            handleOpenPlantSaplingForm={handleOpenPlantSaplingForm}
            handleCameraOpen={handleCameraOpen}
            handlePlantSapling={handlePlantSapling}
          />
        )}
        {letsPlant === true && proceedPay !== true && (
          <ContributionDetails
            handleProcessAndPay={handleProcessAndPay}
            HandlePtoPCancel={HandlePtoPCancel}
          />
        )}
        {proceedPay === true && (
          <ContributionCertificateScreen HandleBackToBTH={HandleBackToBTH} />
        )}
      </div>

      <ContributionPopUp
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "12px !important",
          },
          backdropFilter:
            location.pathname.includes("/contribution") === true
              ? "blur(2px)"
              : "blur(0px)",
        }}
      >
        <Contribution letsPlantFunc={letsPlantFunc} handleClick={handleClick} />
      </ContributionPopUp>
      <DialogDrawer
        open={storeOpen}
        onClose={handleCloseStoreList}
        header={"Available locations"}
        maxWidth="xs"
        // height="700px"
        component={
          <Box>
            {isStoreListLoading
              ? <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                <Typography>Loading...</Typography>
              </Box>
              : (storeData?.length > 0 ?
                <InfiniteScroll
                  dataLength={storeData?.length ?? ""}
                  next={fetchMoreData}
                  hasMore={true}
                  height={matches ? 500 : size?.height - 130}>
                  {storeData?.map((val) => {
                    return (
                      <StoreCard data={val} />
                    )
                  })}
                </InfiniteScroll>
                : <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                  <Typography>No Data Found</Typography>
                </Box>)
            }
          </Box>
        }
      />
      <DialogDrawer
        open={showLogin}
        onClose={() => {
          props.handleMyForest(false);
          setShowLogin(false)
          updateLoginState("is_initial")
        }}
        header={"Login/Register"}
        maxWidth="xs"
        height="300px"
        component={
          <Box>
            <NewLoginScreen setShowLogin={setShowLogin} setIsRegister={setIsRegister} updateState={updateLoginState} setIsVerifyOTP={setIsVerifyOTP} loginData={loginData} />
          </Box>
        }
      />
      <DialogDrawer
        open={isVerifyOTP}
        onClose={() => {
          if (localStorage.getItem("device") === "mobile") {
            openLoginForm();
          }
          props.handleMyForest(false);
          setIsVerifyOTP(!isVerifyOTP);
        }}
        header={"Verify OTP"}
        maxWidth="xs"
        height="300px"
        component={
          <Box>
            <OTPScreen updateState={updateLoginState} setIsVerifyOTP={setIsVerifyOTP} loginData={loginData} setShowLogin={setShowLogin} />
          </Box>
        }
      />
      <DialogDrawer
        open={isRegister}
        onClose={() => {
          if (localStorage.getItem("device") === "mobile") {
            openLoginForm();
          }
          props.handleMyForest(false);
          updateLoginState("is_initial")
          setIsRegister(!isRegister);
        }}
        header={"Register"}
        maxWidth="xs"
        height="300px"
        component={
          <Box>
            <RegisterScreen updateState={updateLoginState} isRegister={isRegister} setIsRegister={setIsRegister} setIsVerifyOTP={setIsVerifyOTP} loginData={loginData} setShowLogin={setShowLogin} />
          </Box>
        }
      />
      <DialogDrawer
        open={isFormOpen}
        onClose={handleClosePlantSaplingForm}
        header={"Plant a Sapling"}
        maxWidth="sm"
        // height="700px"
        padding={isPlantSaplingView ? "20px" : "0px"}
        component={
          <Box>
            {isPlantSaplingView
              ?
              <Box position="relative" height={matches ? "auto" : size?.height - 110}
                sx={{
                  boxShadow: matches ? "0px 1px 20px 0px rgba(0, 0, 0, 0.25)" : "none",
                  borderRadius: matches ? "6px" : "none",
                }}>
                <Box id="tree_details" className={matches ? classes.detailsNew : classes.detailsBox}
                  sx={{
                    boxShadow: matches ? "none" : "0px 1px 20px 0px rgba(0, 0, 0, 0.25)",
                    borderRadius: matches ? "none" : "6px",
                  }}>
                  <Box position={'relative'} >
                    <img src={formData?.displayImage?.length > 0 ? formData?.displayImage : "tree.png"} height={"100%"} style={{
                      borderRadius: "6px 6px 0px 0px",
                      position: "absolute",
                      width: "100%",
                      height: shareImageHeight,
                      objectFit: "cover"
                    }} alt="" />
                    <img ref={imageRef} src="/images/banner_gcc.png" onLoad={handleImageLoad} style={{ borderRadius: "6px 6px 0px 0px", position: "relative", width: '100%', objectFit: "contain" }} alt="" />
                    <Box position={'relative'}>
                      <Box className={classes.imageDiv} />
                      <Box className={classes.imgTextDiv}>
                        <Typography className={classes.imgTextPetName} noWrap>{formData?.petname}</Typography>
                        <Typography mt={1} className={classes.imgText}>{`(${differenceInDays(new Date(), new Date(formData?.planted_on))} days old)`}</Typography>
                        <Typography mt={1} className={classes.imgText} noWrap>{`Planted by ${localStorage.getItem("user_name")}`}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={1} pb={2}>
                    <Typography sx={{ fontSize: "20px", textAlign: "center", fontFamily: "crayond_medium" }}>
                      {"My City, My responsibility! I'm making Chennai green. Are you?"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              : isPlantSaplingSuccess
                ?
                  <Box className={matches?classes.detailsNew :classes.detailsBox}>
                    <Stack p={"20px"} direction={"column"} rowGap={"24px"} justifyContent={"center"} alignItems={"center"}>
                      <Box sx={{ position: "relative", height: "400px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={"/images/bth_logo.png"} style={{ height: "100%", position: "absolute", translate: "translate(-50%, -50%)" }} alt="" />
                        <img src={"/images/Butterfly.gif"} style={{ position: "absolute", bottom: 0, translate: "translate(-50%, -50%)" }} alt="" />
                        <img src={"/images/Butterfly_1.gif"} style={{ position: "absolute", top: 0, translate: "translate(-50%, -50%)" }} alt="" />
                        <img src={"/images/newButterfly.gif"} style={{ position: "absolute", left: "20%", translate: "translate(-50%, -50%)" }} alt="" />
                        <img src={"/images/ButterflyFinal.gif"} style={{ position: "absolute", right: "20%", translate: "translate(-50%, -50%)" }} alt="" />

                      </Box>
                      <Typography sx={{ fontSize: "20px", textAlign: "center", fontFamily: "crayond_medium" }}>
                        {"You're a climate champion. Thanks for contributing to making this world more greener. 🌳🌍"}
                      </Typography>
                    </Stack>
                  </Box>
                : <PlantSaplingForm
                  data={formData}
                  address={address}
                  updateState={updateState}
                  onSubmit={handleSubmitForm}
                  handleCameraOpen={handleCameraOpen} />}
          </Box>
        }
        footer={
          !isPlantSaplingSuccess && !isPlantSaplingView ?
            <Box p={1}>
              <Button className={classes.saveBtn} variant="contained" fullWidth onClick={() => handleSubmitForm()}>Save</Button>
            </Box>
            : isPlantSaplingView &&
            <Box sx={{ display: "flex", justifyContent: 'end', alignItems: 'center', columnGap: "20px", padding: "8px" }}>
              <Button variant="outlined" sx={{
                border: "1px solid #E4E8EE",
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 40,
                cursor: 'pointer',
                color: "#000000",
                "&:hover": {
                  backgroundColor: '#ffffff',
                  border: "1px solid #E4E8EE",

                }
              }} onClick={() => handleClosePlantSaplingForm()}>Cancel</Button>
              <Button variant="contained" sx={{
                backgroundColor: '#71BC44',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                height: 40,
                "&:hover": {
                  backgroundColor: '#71BC44',
                }
              }} onClick={() => shareDetails(formData?.petname?.length > 0 ? formData?.petname : "tree")}>Share</Button>
            </Box>
        }
      />
      <DialogDrawer
        open={isCameraOpen}
        onClose={() => { }}
        padding={"0px"}
        isnotTitle
        maxWidth="xs"
        component={
          <CustomCamera
            size={size}
            updateState={updateState}
            handleCameraOpen={handleCameraOpen}
            handleOpenPlantSaplingForm={handleOpenPlantSaplingForm} />
        }
      />
    </>
  );
};
