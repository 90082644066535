import { Box, Button } from "@mui/material"
import React from "react"
import { TextBox } from "../../../components/textbox"
import { useStyles } from "../style"
import { NetworkCall } from "../../../networkcall"
import { config } from "../../../config"
import { AlertProps, NetWorkCallMethods } from "../../../utils"
import { AlertContext } from "../../../contexts"


const RegisterScreen = ({ setShowLogin = () => false, loginData = "", updateState = () => false, setIsVerifyOTP = () => false, setIsRegister = () => false }) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext);

    const onRegister = () => {

        const payload = {
            "first_name":loginData?.first_name,
            "last_name":loginData?.last_name??null,
            "mobile_no": parseInt(loginData?.mobile_no?.mobile) ?? null,
            "mobile_country_code": (loginData?.mobile_no?.mobile_code).slice(1) ?? null
        }
        NetworkCall(
            `${config.api_url}/user/register`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            setIsVerifyOTP(true)
            setIsRegister(false)
            updateState("is_register",true)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg:res?.data?.message,
            });
        })
        .catch((error)=>{
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg:error?.response?.message,
            });
        })
    }
    return (
        <Box p={1}>
            <TextBox
                label="First Name"
                placeholder="Enter First Name"
                value={loginData?.first_name}
                onChange={(e) => updateState("first_name", e?.target?.value)}
            />
            <Box mt={1}>
                <TextBox
                    label="Last Name"
                    placeholder="Enter Last Name"
                    value={loginData?.last_name}
                    onChange={(e) => updateState("last_name", e?.target?.value)}
                />
            </Box>
            <Box className={classes.bottomFixBtn}>
                <Button variant="contained" fullWidth className={classes.saveBtn} onClick={() => onRegister()} disabled={loginData?.first_name?.length > 0 ? false : true}>Save</Button>
            </Box>

        </Box>
    )
}
export default RegisterScreen