import { useEffect, useState } from "react";
import { NetworkCall } from "../networkcall";
import { config } from "../config";
import { NetWorkCallMethods } from "./constants";
import axios from "axios";
import jwt_decode from "jwt-decode";

/**
 * The below function convert the normal array of object to 
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
   if (!data || !data?.length) {
      return [];
   }

   return data.map((val) => {
      return {
         ...val,
         value: val[valueKey],
         label: val[labelKey],
      };
   });
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
   new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
   if (!string) {
      return string;
   }
   return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
   if (hex) {
      let tempHex = hex.replace("#", "");
      let r = parseInt(tempHex.substring(0, 2), 16);
      let g = parseInt(tempHex.substring(2, 4), 16);
      let b = parseInt(tempHex.substring(4, 6), 16);

      return `rgba(${r},${g},${b},${opacity / 100})`;
   }
   return null;
};

/**
 * The below function will open an document node in a full screen. 
 */
export let OpenFullScreen = (id) => {
   let elem = document.getElementById(id);
   if (elem.requestFullscreen) {
      elem.requestFullscreen();
   } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
   } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
   } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
   }
};

/**
 * The below function will close the full screen of an document
 * node.
 */
export let CloseFullScreen = (id) => {
   if (document.exitFullscreen) {
      document.exitFullscreen();
   } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
   } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
   } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
   }
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
   document.body.scrollTop = 0;
   document.documentElement.scrollTop = 0;
}

// Check the versions
export let semverGreaterThan = (versionA, versionB) => {

   const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
   const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

   while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
   }
   return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = () => {
   if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
         for (const name of names) {
            caches.delete(name);
         }
      });
   }
   // delete browser cache and hard reload
   window.location.reload(true);
};

// To get distance between two lattitude and longitude
export const distance = (lat1, lon1, lat2, lon2, unit) => {
   if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
   }
   else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
         dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === "K") { dist = dist * 1.609344 }
      if (unit === "N") { dist = dist * 0.8684 }
      return dist;
   }
}

export const loadScript = (src, id) => {
   const script = document.createElement("script");
   script.type = "text/javascript";
   script.src = src;
   script.id = id;
   document.head.appendChild(script);
};

// export const isVideoType = (url) => {
//     let regex = /((?:https?(?:%3A%2F%2F|:\/\/))(?:www\.)?(?:\S+)(?:%2F|\/)(?:(?!\.(?:mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg))[^\/])*\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg))(?!\/|\.[a-z]{1,3})/ 
//     return regex.test(url)
// }

const alphabetColors = {
   A: "#C24B23",
   B: "#647D18",
   C: "#2C7E4E",
   D: "#3275A5",
   E: "#5E3B77",
   F: "#773B54",
   G: "#A1652A",
   H: "#366D00",
   I: "#387C65",
   J: "#55678C",
   K: "#9636A9",
   L: "#A93666",
   M: "#867339",
   N: "#497639",
   O: "#228078",
   P: "#464D9F",
   Q: "#994092",
   R: "#890A3F",
   S: "#616116",
   T: "#267D26",
   U: "#3E6C75",
   V: "#453B77",
   W: "#773B63",
   X: "#CB2C6E",
   Y: "#6F2E2E",
   Z: "#962CCB",
};

export const stringAvatar = (name, fontsize, radius = "8px !important") => {
   let capName = name ? name.trim("").toUpperCase() : null;
   return {
      sx: {
         bgcolor: alphabetColors[capName?.[0]],
         fontSize: fontsize ?? "1rem",
         borderRadius: radius,
         fontFamily: 'crayond_medium',
      },
      children:
         capName &&
         (capName.indexOf(" ") >= 0
            ? `${capName.split(" ")[0][0]}${capName.split(" ")[1][0]}`
            : `${capName.split(" ")[0][0]}`),
   };
};
export function useWindowDimensions() {
   const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
   );

   function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
         width,
         height,
      };
   }

   useEffect(() => {
      function handleResize() {
         setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   return windowDimensions;
}

export const loadOptionsApis = async (
   endpoint,
   queryVariables,
   search = "",
   array = [],
   handleLoading = null,
   extractLabel,
   mappingVariable = {},
   manualResponse,
   functionParams = {},
   isPayment
) => {
   let result,
      offset = 0;

   if (search && !Boolean(array?.length)) {
      offset = 0;
   } else {
      offset = array?.length;
   }

   result = await networkCallBackApi(endpoint, { ...queryVariables, search }, handleLoading, offset);

   //Select options should be label,value pairs like ({label:'',value:''})
   if (mappingVariable?.label || mappingVariable?.value) {
      if (mappingVariable?.agreement) {
         result[extractLabel] = result?.data?.map((_) => ({
            label: `${_?.label}`,
            value: _?.value,
         }));

      }
      else if (mappingVariable?.unit) {
         result[extractLabel] = result?.data?.map((_) => ({
            label: `${_?.unit_name}`,
            value: _?.id,
            url: _?.logo,
            label1: _?.unit_no,
            ..._
         }));

      }
      else if (mappingVariable?.timezone) {
         result[extractLabel] = result?.data?.map((_) => ({
            label: `${_?.label} ${_?.gmt_string_offset}`,
            value: _?.value,
            url: _?.gmt_offset,
            label1: _?.country_name,
            value1: _?.label
         }));
      }
      else if (mappingVariable?.extra_invoice) {
         result[extractLabel] = result?.data?.map((_) => ({
            label: `${_?.name}`,
            value: _?.id,
            ..._
         }));
      }
      else if (mappingVariable?.isProperty) {
         result[extractLabel] = result?.data?.map((_) => ({
            label: `${_?.name}`,
            value: _?.id,
            url: _?.logo,
            label1: _?.total_area,
            uom_master: _?.uom,
            property_no: _?.property_no,
            ..._
         }));
      }
      else if (mappingVariable?.isUnit) {
         result[extractLabel] = result?.data?.map((_) => ({
            label: `${_?.name}`,
            value: _?.id,
            url: _?.logo,
            label1: _?.unit_no,
            unit_no: _?.unit_no,
            ..._
         }));
      }
      else if (mappingVariable?.is_all) {

      }
      else {
         result[extractLabel] = result?.[extractLabel]?.map((_) => ({
            label: _?.[mappingVariable?.label ?? "label"],
            value: _?.[mappingVariable?.value ?? "value"],
         }));
      }
   }

   //manually construct array
   if (manualResponse) {
      const { type = "", manualResponseMethod = "" } = functionParams;
      if (manualResponseMethod === "without_extract_label") {
         result[extractLabel] = manualResponse(result, type);
      } else {
         result[extractLabel] = manualResponse(result[extractLabel], type);
      }
   }
   return {
      options: functionParams?.all ?
         (offset === 0 ?
            [{ value: functionParams?.all_value ?? null, label: functionParams?.all_label ?? "All" }, ...result?.[extractLabel]] :
            [...result?.[extractLabel]])
         : [...result?.[extractLabel]],
      hasMore: isPayment ? true : mappingVariable?.extra_invoice ?
         array?.length + result?.data?.[extractLabel]?.length < result?.data?.count :
         array?.length + result?.[extractLabel]?.length < result?.count ?? result?.data?.count,
   };
};

const networkCallBackApi = async (endpoint, variable = {}, handleLoading, offset) => {

   let payload = {
      ...variable,
      offset,
      limit: 10
   };

   const options = await NetworkCall(
      `${config?.api_url}/${endpoint}?offet=${offset}&limit=10&search=${variable?.search}`,
      NetWorkCallMethods.get,
      payload,
      null,
      true,
      false
   )
      .then((response) => {
         let main = response.data;
         handleLoading && handleLoading();
         return main;
      })
      .catch((error) => {
         handleLoading && handleLoading();
         return null;
      });

   return options;
};
export function toEnglishNumber(strNum) {
   var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
   var en = '0123456789'.split('');
   strNum = strNum.replace(/[٠١٢٣٤٥٦٧٨٩]/g, x => en[ar.indexOf(x)]);
   strNum = strNum.replace(/[^\d]/g, '');
   return strNum;
}
export const Sample =
   [
      {
         "name": "Ascension Island",
         "code": "AC",
         "emoji": "🇦🇨",
         "dial_code": "+290",
         "unicode": "U+1F1E6 U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg"
      },
      {
         "name": "Andorra",
         "code": "AD",
         "emoji": "🇦🇩",
         "dial_code": "+376",
         "unicode": "U+1F1E6 U+1F1E9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg"
      },
      {
         "name": "United Arab Emirates",
         "code": "AE",
         "emoji": "🇦🇪",
         "dial_code": "+971",
         "unicode": "U+1F1E6 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg"
      },
      {
         "name": "Afghanistan",
         "code": "AF",
         "emoji": "🇦🇫",
         "dial_code": "+93",
         "unicode": "U+1F1E6 U+1F1EB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg"
      },
      {
         "name": "Anguilla",
         "code": "AI",
         "emoji": "🇦🇮",
         "dial_code": "+1264",
         "unicode": "U+1F1E6 U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg"
      },
      {
         "name": "Albania",
         "dial_code": "+355",
         "code": "AL",
         "emoji": "🇦🇱",
         "unicode": "U+1F1E6 U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg"
      },
      {
         "name": "Armenia",
         "code": "AM",
         "emoji": "🇦🇲",
         "dial_code": "+374",
         "unicode": "U+1F1E6 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg"
      },
      {
         "name": "Angola",
         "code": "AO",
         "emoji": "🇦🇴",
         "dial_code": "+244",
         "unicode": "U+1F1E6 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg"
      },
      {
         "name": "Antarctica",
         "code": "AQ",
         "emoji": "🇦🇶",
         "dial_code": "+672",
         "unicode": "U+1F1E6 U+1F1F6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg"
      },
      {
         "name": "Argentina",
         "code": "AR",
         "emoji": "🇦🇷",
         "dial_code": "+54",
         "unicode": "U+1F1E6 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg"
      },
      {
         "name": "American Samoa",
         "code": "AS",
         "emoji": "🇦🇸",
         "dial_code": "+1684",
         "unicode": "U+1F1E6 U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg"
      },
      {
         "name": "Austria",
         "code": "AT",
         "emoji": "🇦🇹",
         "dial_code": "+43",
         "unicode": "U+1F1E6 U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg"
      },
      {
         "name": "Australia",
         "code": "AU",
         "emoji": "🇦🇺",
         "dial_code": "+61",
         "unicode": "U+1F1E6 U+1F1FA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg"
      },
      {
         "name": "Aruba",
         "code": "AW",
         "emoji": "🇦🇼",
         "dial_code": "+297",
         "unicode": "U+1F1E6 U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg"
      },
      {
         "name": "Åland Islands",
         "code": "AX",
         "emoji": "🇦🇽",
         "dial_code": "+358",
         "unicode": "U+1F1E6 U+1F1FD",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg"
      },
      {
         "name": "Azerbaijan",
         "code": "AZ",
         "emoji": "🇦🇿",
         "dial_code": "+994",
         "unicode": "U+1F1E6 U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg"
      },
      {
         "name": "Bosnia & Herzegovina",
         "code": "BA",
         "dial_code": "+387",
         "emoji": "🇧🇦",
         "unicode": "U+1F1E7 U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg"
      },
      {
         "name": "Barbados",
         "code": "BB",
         "emoji": "🇧🇧",
         "dial_code": "+1246",
         "unicode": "U+1F1E7 U+1F1E7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg"
      },
      {
         "name": "Bangladesh",
         "code": "BD",
         "emoji": "🇧🇩",
         "dial_code": "+880",
         "unicode": "U+1F1E7 U+1F1E9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg"
      },
      {
         "name": "Belgium",
         "code": "BE",
         "emoji": "🇧🇪",
         "dial_code": "+32",
         "unicode": "U+1F1E7 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg"
      },
      {
         "name": "Burkina Faso",
         "code": "BF",
         "emoji": "🇧🇫",
         "unicode": "U+1F1E7 U+1F1EB",
         "dial_code": "+226",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg"
      },
      {
         "name": "Bulgaria",
         "code": "BG",
         "emoji": "🇧🇬",
         "dial_code": "+359",
         "unicode": "U+1F1E7 U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg"
      },
      {
         "name": "Bahrain",
         "code": "BH",
         "emoji": "🇧🇭",
         "dial_code": "+973",
         "unicode": "U+1F1E7 U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
      },
      {
         "name": "Burundi",
         "code": "BI",
         "emoji": "🇧🇮",
         "dial_code": "+257",
         "unicode": "U+1F1E7 U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg"
      },
      {
         "name": "Benin",
         "code": "BJ",
         "emoji": "🇧🇯",
         "dial_code": "+229",
         "unicode": "U+1F1E7 U+1F1EF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg"
      },
      {
         "name": "St. Barthélemy",
         "code": "BL",
         "emoji": "🇧🇱",
         "dial_code": "+590",
         "unicode": "U+1F1E7 U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg"
      },
      {
         "name": "Bermuda",
         "code": "BM",
         "emoji": "🇧🇲",
         "dial_code": "+1441",
         "unicode": "U+1F1E7 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg"
      },
      {
         "name": "Brunei",
         "code": "BN",
         "emoji": "🇧🇳",
         "unicode": "U+1F1E7 U+1F1F3",
         "dial_code": "+673",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg"
      },
      {
         "name": "Bolivia",
         "code": "BO",
         "emoji": "🇧🇴",
         "dial_code": "+591",
         "unicode": "U+1F1E7 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg"
      },
      {
         "name": "Caribbean Netherlands",
         "code": "BQ",
         "emoji": "🇧🇶",
         "dial_code": "+31",
         "unicode": "U+1F1E7 U+1F1F6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg"
      },
      {
         "name": "Brazil",
         "code": "BR",
         "emoji": "🇧🇷",
         "dial_code": "+55",
         "unicode": "U+1F1E7 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg"
      },
      {
         "name": "Bahamas",
         "code": "BS",
         "emoji": "🇧🇸",
         "dial_code": "+1242",
         "unicode": "U+1F1E7 U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg"
      },
      {
         "name": "Bhutan",
         "code": "BT",
         "emoji": "🇧🇹",
         "dial_code": "+975",
         "unicode": "U+1F1E7 U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg"
      },
      {
         "name": "Bouvet Island",
         "code": "BV",
         "emoji": "🇧🇻",
         "dial_code": "+47",
         "unicode": "U+1F1E7 U+1F1FB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg"
      },
      {
         "name": "Botswana",
         "code": "BW",
         "emoji": "🇧🇼",
         "dial_code": "+267",
         "unicode": "U+1F1E7 U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg"
      },
      {
         "name": "Belarus",
         "code": "BY",
         "emoji": "🇧🇾",
         "unicode": "U+1F1E7 U+1F1FE",
         "dial_code": "+375",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg"
      },
      {
         "name": "Belize",
         "code": "BZ",
         "emoji": "🇧🇿",
         "unicode": "U+1F1E7 U+1F1FF",
         "dial_code": "+501",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg"
      },
      {
         "name": "Canada",
         "code": "CA",
         "emoji": "🇨🇦",
         "unicode": "U+1F1E8 U+1F1E6",
         "dial_code": "+1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg"
      },
      {
         "name": "Cocos (Keeling) Islands",
         "code": "CC",
         "emoji": "🇨🇨",
         "dial_code": "+61",
         "unicode": "U+1F1E8 U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg"
      },
      {
         "name": "Congo - Kinshasa",
         "code": "CD",
         "emoji": "🇨🇩",
         "dial_code": "+243",
         "unicode": "U+1F1E8 U+1F1E9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg"
      },
      {
         "name": "Central African Republic",
         "code": "CF",
         "emoji": "🇨🇫",
         "dial_code": "+236",
         "unicode": "U+1F1E8 U+1F1EB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg"
      },
      {
         "name": "Congo - Brazzaville",
         "code": "CG",
         "emoji": "🇨🇬",
         "dial_code": "+242",
         "unicode": "U+1F1E8 U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg"
      },
      {
         "name": "Switzerland",
         "code": "CH",
         "emoji": "🇨🇭",
         "dial_code": "+41",
         "unicode": "U+1F1E8 U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg"
      },
      {
         "name": "Côte d’Ivoire",
         "code": "CI",
         "emoji": "🇨🇮",
         "dial_code": "+225",
         "unicode": "U+1F1E8 U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg"
      },
      {
         "name": "Cook Islands",
         "code": "CK",
         "emoji": "🇨🇰",
         "unicode": "U+1F1E8 U+1F1F0",
         "dial_code": "+682",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg"
      },
      {
         "name": "Chile",
         "code": "CL",
         "emoji": "🇨🇱",
         "dial_code": "+56",
         "unicode": "U+1F1E8 U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg"
      },
      {
         "name": "Cameroon",
         "code": "CM",
         "emoji": "🇨🇲",
         "dial_code": "+237",
         "unicode": "U+1F1E8 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg"
      },
      {
         "name": "China",
         "code": "CN",
         "emoji": "🇨🇳",
         "dial_code": "+86",
         "unicode": "U+1F1E8 U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg"
      },
      {
         "name": "Colombia",
         "code": "CO",
         "emoji": "🇨🇴",
         "dial_code": "+57",
         "unicode": "U+1F1E8 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg"
      },
      {
         "name": "Costa Rica",
         "code": "CR",
         "emoji": "🇨🇷",
         "dial_code": "+506",
         "unicode": "U+1F1E8 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg"
      },
      {
         "name": "Cuba",
         "code": "CU",
         "emoji": "🇨🇺",
         "dial_code": "+53",
         "unicode": "U+1F1E8 U+1F1FA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg"
      },
      {
         "name": "Cape Verde",
         "code": "CV",
         "emoji": "🇨🇻",
         "unicode": "U+1F1E8 U+1F1FB",
         "dial_code": "+238",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg"
      },
      {
         "name": "Curaçao",
         "code": "CW",
         "emoji": "🇨🇼",
         "dial_code": "+599",
         "unicode": "U+1F1E8 U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg"
      },
      {
         "name": "Christmas Island",
         "code": "CX",
         "emoji": "🇨🇽",
         "dial_code": "+61",
         "unicode": "U+1F1E8 U+1F1FD",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg"
      },
      {
         "name": "Cyprus",
         "code": "CY",
         "emoji": "🇨🇾",
         "dial_code": "+357",
         "unicode": "U+1F1E8 U+1F1FE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg"
      },
      {
         "name": "Czechia",
         "code": "CZ",
         "emoji": "🇨🇿",
         "dial_code": "+420",
         "unicode": "U+1F1E8 U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg"
      },
      {
         "name": "Germany",
         "code": "DE",
         "emoji": "🇩🇪",
         "dial_code": "+49",
         "unicode": "U+1F1E9 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg"
      },
      {
         "name": "Diego Garcia",
         "code": "DG",
         "emoji": "🇩🇬",
         "dial_code": "+246",
         "unicode": "U+1F1E9 U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg"
      },
      {
         "name": "Djibouti",
         "code": "DJ",
         "emoji": "🇩🇯",
         "dial_code": "+253",
         "unicode": "U+1F1E9 U+1F1EF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg"
      },
      {
         "name": "Denmark",
         "code": "DK",
         "emoji": "🇩🇰",
         "dial_code": "+45",
         "unicode": "U+1F1E9 U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg"
      },
      {
         "name": "Dominica",
         "code": "DM",
         "emoji": "🇩🇲",
         "dial_code": "+1767",
         "unicode": "U+1F1E9 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg"
      },
      {
         "name": "Dominican Republic",
         "code": "DO",
         "emoji": "🇩🇴",
         "dial_code": "+1849",
         "unicode": "U+1F1E9 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg"
      },
      {
         "name": "Algeria",
         "code": "DZ",
         "emoji": "🇩🇿",
         "dial_code": "+213",
         "unicode": "U+1F1E9 U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg"
      },
      {
         "name": "Ecuador",
         "code": "EC",
         "emoji": "🇪🇨",
         "dial_code": "+593",
         "unicode": "U+1F1EA U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg"
      },
      {
         "name": "Estonia",
         "code": "EE",
         "emoji": "🇪🇪",
         "dial_code": "+372",
         "unicode": "U+1F1EA U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg"
      },
      {
         "name": "Egypt",
         "code": "EG",
         "emoji": "🇪🇬",
         "dial_code": "+20",
         "unicode": "U+1F1EA U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg"
      },
      {
         "name": "Western Sahara",
         "code": "EH",
         "emoji": "🇪🇭",
         "dial_code": "+212",
         "unicode": "U+1F1EA U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg"
      },
      {
         "name": "Eritrea",
         "code": "ER",
         "emoji": "🇪🇷",
         "dial_code": "+291",
         "unicode": "U+1F1EA U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg"
      },
      {
         "name": "Spain",
         "code": "ES",
         "emoji": "🇪🇸",
         "dial_code": "+34",
         "unicode": "U+1F1EA U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg"
      },
      {
         "name": "Ethiopia",
         "code": "ET",
         "emoji": "🇪🇹",
         "unicode": "U+1F1EA U+1F1F9",
         "dial_code": "+251",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg"
      },
      {
         "name": "Finland",
         "code": "FI",
         "emoji": "🇫🇮",
         "dial_code": "+358",
         "unicode": "U+1F1EB U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg"
      },
      {
         "name": "Fiji",
         "code": "FJ",
         "emoji": "🇫🇯",
         "dial_code": "+679",
         "unicode": "U+1F1EB U+1F1EF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg"
      },
      {
         "name": "Falkland Islands",
         "code": "FK",
         "emoji": "🇫🇰",
         "dial_code": "+500",
         "unicode": "U+1F1EB U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg"
      },
      {
         "name": "Micronesia",
         "code": "FM",
         "emoji": "🇫🇲",
         "unicode": "U+1F1EB U+1F1F2",
         "dial_code": "+691",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg"
      },
      {
         "name": "Faroe Islands",
         "code": "FO",
         "emoji": "🇫🇴",
         "dial_code": "+298",
         "unicode": "U+1F1EB U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg"
      },
      {
         "name": "France",
         "code": "FR",
         "emoji": "🇫🇷",
         "dial_code": "+33",
         "unicode": "U+1F1EB U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg"
      },
      {
         "name": "Gabon",
         "code": "GA",
         "emoji": "🇬🇦",
         "dial_code": "+241",
         "unicode": "U+1F1EC U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg"
      },
      {
         "name": "United Kingdom",
         "code": "GB",
         "emoji": "🇬🇧",
         "dial_code": "+44",
         "unicode": "U+1F1EC U+1F1E7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
      },
      {
         "name": "Grenada",
         "code": "GD",
         "emoji": "🇬🇩",
         "dial_code": "+1473",
         "unicode": "U+1F1EC U+1F1E9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg"
      },
      {
         "name": "Georgia",
         "code": "GE",
         "emoji": "🇬🇪",
         "dial_code": "+995",
         "unicode": "U+1F1EC U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg"
      },
      {
         "name": "French Guiana",
         "code": "GF",
         "emoji": "🇬🇫",
         "dial_code": "+594",
         "unicode": "U+1F1EC U+1F1EB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg"
      },
      {
         "name": "Guernsey",
         "code": "GG",
         "emoji": "🇬🇬",
         "dial_code": "+44",
         "unicode": "U+1F1EC U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg"
      },
      {
         "name": "Ghana",
         "code": "GH",
         "emoji": "🇬🇭",
         "dial_code": "+233",
         "unicode": "U+1F1EC U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg"
      },
      {
         "name": "Gibraltar",
         "code": "GI",
         "emoji": "🇬🇮",
         "dial_code": "+350",
         "unicode": "U+1F1EC U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg"
      },
      {
         "name": "Greenland",
         "code": "GL",
         "emoji": "🇬🇱",
         "dial_code": "+299",
         "unicode": "U+1F1EC U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg"
      },
      {
         "name": "Gambia",
         "code": "GM",
         "emoji": "🇬🇲",
         "dial_code": "+220",
         "unicode": "U+1F1EC U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg"
      },
      {
         "name": "Guinea",
         "code": "GN",
         "emoji": "🇬🇳",
         "dial_code": "+224",
         "unicode": "U+1F1EC U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg"
      },
      {
         "name": "Guadeloupe",
         "code": "GP",
         "emoji": "🇬🇵",
         "dial_code": "+590",
         "unicode": "U+1F1EC U+1F1F5",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg"
      },
      {
         "name": "Equatorial Guinea",
         "code": "GQ",
         "emoji": "🇬🇶",
         "dial_code": "+240",
         "unicode": "U+1F1EC U+1F1F6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg"
      },
      {
         "name": "Greece",
         "code": "GR",
         "emoji": "🇬🇷",
         "unicode": "U+1F1EC U+1F1F7",
         "dial_code": "+30",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg"
      },
      {
         "name": "South Georgia & South Sandwich Islands",
         "code": "GS",
         "emoji": "🇬🇸",
         "dial_code": "+500",
         "unicode": "U+1F1EC U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg"
      },
      {
         "name": "Guatemala",
         "code": "GT",
         "emoji": "🇬🇹",
         "dial_code": "+502",
         "unicode": "U+1F1EC U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg"
      },
      {
         "name": "Guam",
         "code": "GU",
         "emoji": "🇬🇺",
         "dial_code": "+1671",
         "unicode": "U+1F1EC U+1F1FA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg"
      },
      {
         "name": "Guinea-Bissau",
         "code": "GW",
         "emoji": "🇬🇼",
         "unicode": "U+1F1EC U+1F1FC",
         "dial_code": "+245",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg"
      },
      {
         "name": "Guyana",
         "code": "GY",
         "emoji": "🇬🇾",
         "dial_code": "+592",
         "unicode": "U+1F1EC U+1F1FE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg"
      },
      {
         "name": "Hong Kong SAR China",
         "code": "HK",
         "emoji": "🇭🇰",
         "dial_code": "+852",
         "unicode": "U+1F1ED U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg"
      },
      {
         "name": "Heard & McDonald Islands",
         "code": "HM",
         "emoji": "🇭🇲",
         "dial_code": "+672",
         "unicode": "U+1F1ED U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg"
      },
      {
         "name": "Honduras",
         "code": "HN",
         "emoji": "🇭🇳",
         "dial_code": "+504",
         "unicode": "U+1F1ED U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg"
      },
      {
         "name": "Croatia",
         "code": "HR",
         "emoji": "🇭🇷",
         "dial_code": "+385",
         "unicode": "U+1F1ED U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg"
      },
      {
         "name": "Haiti",
         "code": "HT",
         "emoji": "🇭🇹",
         "dial_code": "+509",
         "unicode": "U+1F1ED U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg"
      },
      {
         "name": "Hungary",
         "code": "HU",
         "emoji": "🇭🇺",
         "unicode": "U+1F1ED U+1F1FA",
         "dial_code": "+36",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg"
      },
      {
         "name": "Indonesia",
         "code": "ID",
         "emoji": "🇮🇩",
         "dial_code": "+62",
         "unicode": "U+1F1EE U+1F1E9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg"
      },
      {
         "name": "Ireland",
         "code": "IE",
         "emoji": "🇮🇪",
         "dial_code": "+353",
         "unicode": "U+1F1EE U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg"
      },
      {
         "name": "Israel",
         "code": "IL",
         "emoji": "🇮🇱",
         "dial_code": "+972",
         "unicode": "U+1F1EE U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg"
      },
      {
         "name": "Isle of Man",
         "code": "IM",
         "emoji": "🇮🇲",
         "dial_code": "+44",
         "unicode": "U+1F1EE U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg"
      },
      {
         "name": "India",
         "code": "IN",
         "emoji": "🇮🇳",
         "dial_code": "+91",
         "unicode": "U+1F1EE U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
      },
      {
         "name": "British Indian Ocean Territory",
         "code": "IO",
         "emoji": "🇮🇴",
         "unicode": "U+1F1EE U+1F1F4",
         "dial_code": "+246",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg"
      },
      {
         "name": "Iraq",
         "code": "IQ",
         "emoji": "🇮🇶",
         "dial_code": "+964",
         "unicode": "U+1F1EE U+1F1F6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg"
      },
      {
         "name": "Iran",
         "code": "IR",
         "emoji": "🇮🇷",
         "dial_code": "+98",
         "unicode": "U+1F1EE U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg"
      },
      {
         "name": "Iceland",
         "code": "IS",
         "emoji": "🇮🇸",
         "dial_code": "+354",
         "unicode": "U+1F1EE U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg"
      },
      {
         "name": "Italy",
         "code": "IT",
         "emoji": "🇮🇹",
         "dial_code": "+39",
         "unicode": "U+1F1EE U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg"
      },
      {
         "name": "Jersey",
         "code": "JE",
         "emoji": "🇯🇪",
         "dial_code": "+44",
         "unicode": "U+1F1EF U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg"
      },
      {
         "name": "Jamaica",
         "code": "JM",
         "emoji": "🇯🇲",
         "dial_code": "+1876",
         "unicode": "U+1F1EF U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg"
      },
      {
         "name": "Jordan",
         "code": "JO",
         "emoji": "🇯🇴",
         "dial_code": "+962",
         "unicode": "U+1F1EF U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg"
      },
      {
         "name": "Japan",
         "code": "JP",
         "emoji": "🇯🇵",
         "dial_code": "+81",
         "unicode": "U+1F1EF U+1F1F5",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg"
      },
      {
         "name": "Kenya",
         "code": "KE",
         "emoji": "🇰🇪",
         "dial_code": "+254",
         "unicode": "U+1F1F0 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg"
      },
      {
         "name": "Kyrgyzstan",
         "code": "KG",
         "emoji": "🇰🇬",
         "unicode": "U+1F1F0 U+1F1EC",
         "dial_code": "+996",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg"
      },
      {
         "name": "Cambodia",
         "code": "KH",
         "emoji": "🇰🇭",
         "dial_code": "+855",
         "unicode": "U+1F1F0 U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg"
      },
      {
         "name": "Kiribati",
         "code": "KI",
         "emoji": "🇰🇮",
         "dial_code": "+686",
         "unicode": "U+1F1F0 U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg"
      },
      {
         "name": "Comoros",
         "code": "KM",
         "emoji": "🇰🇲",
         "dial_code": "+269",
         "unicode": "U+1F1F0 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg"
      },
      {
         "name": "St. Kitts & Nevis",
         "code": "KN",
         "emoji": "🇰🇳",
         "dial_code": "+1869",
         "unicode": "U+1F1F0 U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg"
      },
      {
         "name": "North Korea",
         "code": "KP",
         "emoji": "🇰🇵",
         "unicode": "U+1F1F0 U+1F1F5",
         "dial_code": "+850",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg"
      },
      {
         "name": "South Korea",
         "code": "KR",
         "emoji": "🇰🇷",
         "dial_code": "+82",
         "unicode": "U+1F1F0 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg"
      },
      {
         "name": "Kuwait",
         "code": "KW",
         "emoji": "🇰🇼",
         "dial_code": "+965",
         "unicode": "U+1F1F0 U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg"
      },
      {
         "name": "Cayman Islands",
         "code": "KY",
         "emoji": "🇰🇾",
         "dial_code": "+345",
         "unicode": "U+1F1F0 U+1F1FE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg"
      },
      {
         "name": "Kazakhstan",
         "code": "KZ",
         "emoji": "🇰🇿",
         "dial_code": "+7",
         "unicode": "U+1F1F0 U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg"
      },
      {
         "name": "Laos",
         "code": "LA",
         "emoji": "🇱🇦",
         "dial_code": "+856",
         "unicode": "U+1F1F1 U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg"
      },
      {
         "name": "Lebanon",
         "code": "LB",
         "emoji": "🇱🇧",
         "dial_code": "+961",
         "unicode": "U+1F1F1 U+1F1E7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg"
      },
      {
         "name": "St. Lucia",
         "code": "LC",
         "emoji": "🇱🇨",
         "dial_code": "+1758",
         "unicode": "U+1F1F1 U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg"
      },
      {
         "name": "Liechtenstein",
         "code": "LI",
         "emoji": "🇱🇮",
         "dial_code": "+423",
         "unicode": "U+1F1F1 U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg"
      },
      {
         "name": "Sri Lanka",
         "code": "LK",
         "emoji": "🇱🇰",
         "dial_code": "+94",
         "unicode": "U+1F1F1 U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg"
      },
      {
         "name": "Liberia",
         "code": "LR",
         "emoji": "🇱🇷",
         "unicode": "U+1F1F1 U+1F1F7",
         "dial_code": "+231",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg"
      },
      {
         "name": "Lesotho",
         "code": "LS",
         "emoji": "🇱🇸",
         "unicode": "U+1F1F1 U+1F1F8",
         "dial_code": "+266",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg"
      },
      {
         "name": "Lithuania",
         "code": "LT",
         "emoji": "🇱🇹",
         "dial_code": "+370",
         "unicode": "U+1F1F1 U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg"
      },
      {
         "name": "Luxembourg",
         "code": "LU",
         "emoji": "🇱🇺",
         "dial_code": "+352",
         "unicode": "U+1F1F1 U+1F1FA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg"
      },
      {
         "name": "Latvia",
         "code": "LV",
         "emoji": "🇱🇻",
         "dial_code": "+371",
         "unicode": "U+1F1F1 U+1F1FB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg"
      },
      {
         "name": "Libya",
         "code": "LY",
         "emoji": "🇱🇾",
         "dial_code": "+218",
         "unicode": "U+1F1F1 U+1F1FE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg"
      },
      {
         "name": "Morocco",
         "code": "MA",
         "emoji": "🇲🇦",
         "dial_code": "+212",
         "unicode": "U+1F1F2 U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg"
      },
      {
         "name": "Monaco",
         "code": "MC",
         "emoji": "🇲🇨",
         "dial_code": "+377",
         "unicode": "U+1F1F2 U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg"
      },
      {
         "name": "Moldova",
         "code": "MD",
         "emoji": "🇲🇩",
         "dial_code": "+373",
         "unicode": "U+1F1F2 U+1F1E9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg"
      },
      {
         "name": "Montenegro",
         "code": "ME",
         "emoji": "🇲🇪",
         "dial_code": "+382",
         "unicode": "U+1F1F2 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg"
      },
      {
         "name": "St. Martin",
         "code": "MF",
         "emoji": "🇲🇫",
         "dial_code": "+590",
         "unicode": "U+1F1F2 U+1F1EB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg"
      },
      {
         "name": "Madagascar",
         "code": "MG",
         "emoji": "🇲🇬",
         "dial_code": "+261",
         "unicode": "U+1F1F2 U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg"
      },
      {
         "name": "Marshall Islands",
         "code": "MH",
         "emoji": "🇲🇭",
         "dial_code": "+692",
         "unicode": "U+1F1F2 U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg"
      },
      {
         "name": "North Macedonia",
         "code": "MK",
         "emoji": "🇲🇰",
         "dial_code": "+389",
         "unicode": "U+1F1F2 U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg"
      },
      {
         "name": "Mali",
         "code": "ML",
         "emoji": "🇲🇱",
         "dial_code": "+223",
         "unicode": "U+1F1F2 U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg"
      },
      {
         "name": "Myanmar (Burma)",
         "code": "MM",
         "emoji": "🇲🇲",
         "dial_code": "+95",
         "unicode": "U+1F1F2 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg"
      },
      {
         "name": "Mongolia",
         "code": "MN",
         "emoji": "🇲🇳",
         "dial_code": "+976",
         "unicode": "U+1F1F2 U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg"
      },
      {
         "name": "Macao SAR China",
         "code": "MO",
         "emoji": "🇲🇴",
         "dial_code": "+853",
         "unicode": "U+1F1F2 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg"
      },
      {
         "name": "Northern Mariana Islands",
         "code": "MP",
         "emoji": "🇲🇵",
         "dial_code": "+1670",
         "unicode": "U+1F1F2 U+1F1F5",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg"
      },
      {
         "name": "Martinique",
         "code": "MQ",
         "emoji": "🇲🇶",
         "dial_code": "+596",
         "unicode": "U+1F1F2 U+1F1F6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg"
      },
      {
         "name": "Mauritania",
         "code": "MR",
         "emoji": "🇲🇷",
         "dial_code": "+222",
         "unicode": "U+1F1F2 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg"
      },
      {
         "name": "Montserrat",
         "code": "MS",
         "emoji": "🇲🇸",
         "dial_code": "+1664",
         "unicode": "U+1F1F2 U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg"
      },
      {
         "name": "Malta",
         "code": "MT",
         "emoji": "🇲🇹",
         "dial_code": "+356",
         "unicode": "U+1F1F2 U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg"
      },
      {
         "name": "Mauritius",
         "code": "MU",
         "emoji": "🇲🇺",
         "dial_code": "+230",
         "unicode": "U+1F1F2 U+1F1FA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg"
      },
      {
         "name": "Maldives",
         "code": "MV",
         "emoji": "🇲🇻",
         "dial_code": "+960",
         "unicode": "U+1F1F2 U+1F1FB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg"
      },
      {
         "name": "Malawi",
         "code": "MW",
         "emoji": "🇲🇼",
         "dial_code": "+265",
         "unicode": "U+1F1F2 U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg"
      },
      {
         "name": "Mexico",
         "code": "MX",
         "emoji": "🇲🇽",
         "dial_code": "+52",
         "unicode": "U+1F1F2 U+1F1FD",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg"
      },
      {
         "name": "Malaysia",
         "code": "MY",
         "emoji": "🇲🇾",
         "dial_code": "+60",
         "unicode": "U+1F1F2 U+1F1FE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg"
      },
      {
         "name": "Mozambique",
         "code": "MZ",
         "emoji": "🇲🇿",
         "dial_code": "+258",
         "unicode": "U+1F1F2 U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg"
      },
      {
         "name": "Namibia",
         "code": "NA",
         "emoji": "🇳🇦",
         "dial_code": "+264",
         "unicode": "U+1F1F3 U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg"
      },
      {
         "name": "New Caledonia",
         "code": "NC",
         "emoji": "🇳🇨",
         "dial_code": "+687",
         "unicode": "U+1F1F3 U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg"
      },
      {
         "name": "Niger",
         "code": "NE",
         "emoji": "🇳🇪",
         "dial_code": "+227",
         "unicode": "U+1F1F3 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg"
      },
      {
         "name": "Norfolk Island",
         "code": "NF",
         "emoji": "🇳🇫",
         "dial_code": "+672",
         "unicode": "U+1F1F3 U+1F1EB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg"
      },
      {
         "name": "Nigeria",
         "code": "NG",
         "emoji": "🇳🇬",
         "dial_code": "+234",
         "unicode": "U+1F1F3 U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg"
      },
      {
         "name": "Nicaragua",
         "code": "NI",
         "emoji": "🇳🇮",
         "dial_code": "+505",
         "unicode": "U+1F1F3 U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg"
      },
      {
         "name": "Netherlands",
         "code": "NL",
         "emoji": "🇳🇱",
         "dial_code": "+31",
         "unicode": "U+1F1F3 U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg"
      },
      {
         "name": "Norway",
         "code": "NO",
         "emoji": "🇳🇴",
         "dial_code": "+47",
         "unicode": "U+1F1F3 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg"
      },
      {
         "name": "Nepal",
         "code": "NP",
         "emoji": "🇳🇵",
         "dial_code": "+977",
         "unicode": "U+1F1F3 U+1F1F5",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg"
      },
      {
         "name": "Nauru",
         "code": "NR",
         "emoji": "🇳🇷",
         "dial_code": "+674",
         "unicode": "U+1F1F3 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg"
      },
      {
         "name": "Niue",
         "code": "NU",
         "emoji": "🇳🇺",
         "dial_code": "+683",
         "unicode": "U+1F1F3 U+1F1FA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg"
      },
      {
         "name": "New Zealand",
         "code": "NZ",
         "emoji": "🇳🇿",
         "dial_code": "+64",
         "unicode": "U+1F1F3 U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg"
      },
      {
         "name": "Oman",
         "code": "OM",
         "emoji": "🇴🇲",
         "dial_code": "+968",
         "unicode": "U+1F1F4 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg"
      },
      {
         "name": "Panama",
         "code": "PA",
         "emoji": "🇵🇦",
         "dial_code": "+507",
         "unicode": "U+1F1F5 U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg"
      },
      {
         "name": "Peru",
         "code": "PE",
         "emoji": "🇵🇪",
         "dial_code": "+51",
         "unicode": "U+1F1F5 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg"
      },
      {
         "name": "French Polynesia",
         "code": "PF",
         "emoji": "🇵🇫",
         "dial_code": "+689",
         "unicode": "U+1F1F5 U+1F1EB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg"
      },
      {
         "name": "Papua New Guinea",
         "code": "PG",
         "emoji": "🇵🇬",
         "unicode": "U+1F1F5 U+1F1EC",
         "dial_code": "+675",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg"
      },
      {
         "name": "Philippines",
         "code": "PH",
         "emoji": "🇵🇭",
         "dial_code": "+63",
         "unicode": "U+1F1F5 U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg"
      },
      {
         "name": "Pakistan",
         "code": "PK",
         "emoji": "🇵🇰",
         "dial_code": "+92",
         "unicode": "U+1F1F5 U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg"
      },
      {
         "name": "Poland",
         "code": "PL",
         "emoji": "🇵🇱",
         "dial_code": "+48",
         "unicode": "U+1F1F5 U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg"
      },
      {
         "name": "St. Pierre & Miquelon",
         "code": "PM",
         "emoji": "🇵🇲",
         "dial_code": "+508",
         "unicode": "U+1F1F5 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg"
      },
      {
         "name": "Pitcairn Islands",
         "code": "PN",
         "emoji": "🇵🇳",
         "dial_code": "+64",
         "unicode": "U+1F1F5 U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg"
      },
      {
         "name": "Puerto Rico",
         "code": "PR",
         "emoji": "🇵🇷",
         "dial_code": "+1939",
         "unicode": "U+1F1F5 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg"
      },
      {
         "name": "Palestinian Territories",
         "code": "PS",
         "emoji": "🇵🇸",
         "dial_code": "+970",
         "unicode": "U+1F1F5 U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg"
      },
      {
         "name": "Portugal",
         "code": "PT",
         "emoji": "🇵🇹",
         "dial_code": "+351",
         "unicode": "U+1F1F5 U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg"
      },
      {
         "name": "Palau",
         "code": "PW",
         "emoji": "🇵🇼",
         "dial_code": "+680",
         "unicode": "U+1F1F5 U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg"
      },
      {
         "name": "Paraguay",
         "code": "PY",
         "emoji": "🇵🇾",
         "dial_code": "+595",
         "unicode": "U+1F1F5 U+1F1FE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg"
      },
      {
         "name": "Qatar",
         "code": "QA",
         "emoji": "🇶🇦",
         "dial_code": "+974",
         "unicode": "U+1F1F6 U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg"
      },
      {
         "name": "Réunion",
         "code": "RE",
         "emoji": "🇷🇪",
         "dial_code": "+262",
         "unicode": "U+1F1F7 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg"
      },
      {
         "name": "Romania",
         "code": "RO",
         "emoji": "🇷🇴",
         "dial_code": "+40",
         "unicode": "U+1F1F7 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg"
      },
      {
         "name": "Serbia",
         "code": "RS",
         "emoji": "🇷🇸",
         "dial_code": "+381",
         "unicode": "U+1F1F7 U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg"
      },
      {
         "name": "Russia",
         "code": "RU",
         "emoji": "🇷🇺",
         "dial_code": "+7",
         "unicode": "U+1F1F7 U+1F1FA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg"
      },
      {
         "name": "Rwanda",
         "code": "RW",
         "emoji": "🇷🇼",
         "dial_code": "+250",
         "unicode": "U+1F1F7 U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg"
      },
      {
         "name": "Saudi Arabia",
         "code": "SA",
         "emoji": "🇸🇦",
         "dial_code": "+966",
         "unicode": "U+1F1F8 U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg"
      },
      {
         "name": "Solomon Islands",
         "code": "SB",
         "emoji": "🇸🇧",
         "dial_code": "+677",
         "unicode": "U+1F1F8 U+1F1E7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg"
      },
      {
         "name": "Seychelles",
         "code": "SC",
         "emoji": "🇸🇨",
         "dial_code": "+248",
         "unicode": "U+1F1F8 U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg"
      },
      {
         "name": "Sudan",
         "code": "SD",
         "emoji": "🇸🇩",
         "dial_code": "+249",
         "unicode": "U+1F1F8 U+1F1E9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg"
      },
      {
         "name": "Sweden",
         "code": "SE",
         "emoji": "🇸🇪",
         "dial_code": "+46",
         "unicode": "U+1F1F8 U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg"
      },
      {
         "name": "Singapore",
         "code": "SG",
         "emoji": "🇸🇬",
         "dial_code": "+65",
         "unicode": "U+1F1F8 U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg"
      },
      {
         "name": "St. Helena",
         "code": "SH",
         "emoji": "🇸🇭",
         "dial_code": "+290",
         "unicode": "U+1F1F8 U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg"
      },
      {
         "name": "Slovenia",
         "code": "SI",
         "emoji": "🇸🇮",
         "dial_code": "+386",
         "unicode": "U+1F1F8 U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg"
      },
      {
         "name": "Svalbard & Jan Mayen",
         "code": "SJ",
         "emoji": "🇸🇯",
         "dial_code": "+47",
         "unicode": "U+1F1F8 U+1F1EF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg"
      },
      {
         "name": "Slovakia",
         "code": "SK",
         "emoji": "🇸🇰",
         "dial_code": "+421",
         "unicode": "U+1F1F8 U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg"
      },
      {
         "name": "Sierra Leone",
         "code": "SL",
         "emoji": "🇸🇱",
         "dial_code": "+232",
         "unicode": "U+1F1F8 U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg"
      },
      {
         "name": "San Marino",
         "code": "SM",
         "emoji": "🇸🇲",
         "dial_code": "+378",
         "unicode": "U+1F1F8 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg"
      },
      {
         "name": "Senegal",
         "code": "SN",
         "emoji": "🇸🇳",
         "dial_code": "+221",
         "unicode": "U+1F1F8 U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg"
      },
      {
         "name": "Somalia",
         "code": "SO",
         "emoji": "🇸🇴",
         "dial_code": "+252",
         "unicode": "U+1F1F8 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg"
      },
      {
         "name": "Suriname",
         "code": "SR",
         "emoji": "🇸🇷",
         "dial_code": "+597",
         "unicode": "U+1F1F8 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg"
      },
      {
         "name": "South Sudan",
         "code": "SS",
         "emoji": "🇸🇸",
         "dial_code": "+211",
         "unicode": "U+1F1F8 U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg"
      },
      {
         "name": "São Tomé & Príncipe",
         "code": "ST",
         "emoji": "🇸🇹",
         "dial_code": "+239",
         "unicode": "U+1F1F8 U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg"
      },
      {
         "name": "El Salvador",
         "code": "SV",
         "emoji": "🇸🇻",
         "dial_code": "+503",
         "unicode": "U+1F1F8 U+1F1FB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg"
      },
      {
         "name": "Sint Maarten",
         "code": "SX",
         "emoji": "🇸🇽",
         "unicode": "U+1F1F8 U+1F1FD",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg"
      },
      {
         "name": "Syria",
         "code": "SY",
         "emoji": "🇸🇾",
         "dial_code": "+41",
         "unicode": "U+1F1F8 U+1F1FE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg"
      },
      {
         "name": "Eswatini",
         "code": "SZ",
         "emoji": "🇸🇿",
         "dial_code": "+268",
         "unicode": "U+1F1F8 U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg"
      },
      {
         "name": "Tristan da Cunha",
         "code": "TA",
         "emoji": "🇹🇦",
         "unicode": "U+1F1F9 U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TA.svg"
      },
      {
         "name": "Turks & Caicos Islands",
         "code": "TC",
         "emoji": "🇹🇨",
         "dial_code": "+1649",
         "unicode": "U+1F1F9 U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg"
      },
      {
         "name": "Chad",
         "code": "TD",
         "emoji": "🇹🇩",
         "unicode": "U+1F1F9 U+1F1E9",
         "dial_code": "+235",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg"
      },
      {
         "name": "French Southern Territories",
         "code": "TF",
         "emoji": "🇹🇫",
         "dial_code": "+262",
         "unicode": "U+1F1F9 U+1F1EB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg"
      },
      {
         "name": "Togo",
         "code": "TG",
         "emoji": "🇹🇬",
         "dial_code": "+228",
         "unicode": "U+1F1F9 U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg"
      },
      {
         "name": "Thailand",
         "code": "TH",
         "emoji": "🇹🇭",
         "dial_code": "+66",
         "unicode": "U+1F1F9 U+1F1ED",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg"
      },
      {
         "name": "Tajikistan",
         "code": "TJ",
         "emoji": "🇹🇯",
         "dial_code": "+992",
         "unicode": "U+1F1F9 U+1F1EF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg"
      },
      {
         "name": "Tokelau",
         "code": "TK",
         "emoji": "🇹🇰",
         "dial_code": "+690",
         "unicode": "U+1F1F9 U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg"
      },
      {
         "name": "Timor-Leste",
         "code": "TL",
         "emoji": "🇹🇱",
         "dial_code": "+670",
         "unicode": "U+1F1F9 U+1F1F1",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg"
      },
      {
         "name": "Turkmenistan",
         "code": "TM",
         "emoji": "🇹🇲",
         "dial_code": "+993",
         "unicode": "U+1F1F9 U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg"
      },
      {
         "name": "Tunisia",
         "code": "TN",
         "emoji": "🇹🇳",
         "dial_code": "+216",
         "unicode": "U+1F1F9 U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg"
      },
      {
         "name": "Tonga",
         "code": "TO",
         "emoji": "🇹🇴",
         "dial_code": "+676",
         "unicode": "U+1F1F9 U+1F1F4",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg"
      },
      {
         "name": "Turkey",
         "code": "TR",
         "emoji": "🇹🇷",
         "dial_code": "+90",
         "unicode": "U+1F1F9 U+1F1F7",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg"
      },
      {
         "name": "Trinidad & Tobago",
         "code": "TT",
         "emoji": "🇹🇹",
         "dial_code": "+1868",
         "unicode": "U+1F1F9 U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg"
      },
      {
         "name": "Tuvalu",
         "code": "TV",
         "emoji": "🇹🇻",
         "dial_code": "+688",
         "unicode": "U+1F1F9 U+1F1FB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg"
      },
      {
         "name": "Taiwan",
         "code": "TW",
         "emoji": "🇹🇼",
         "dial_code": "+886",
         "unicode": "U+1F1F9 U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg"
      },
      {
         "name": "Tanzania",
         "code": "TZ",
         "emoji": "🇹🇿",
         "dial_code": "+255",
         "unicode": "U+1F1F9 U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg"
      },
      {
         "name": "Ukraine",
         "code": "UA",
         "emoji": "🇺🇦",
         "dial_code": "+380",
         "unicode": "U+1F1FA U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg"
      },
      {
         "name": "Uganda",
         "code": "UG",
         "emoji": "🇺🇬",
         "dial_code": "+256",
         "unicode": "U+1F1FA U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg"
      },
      // {
      //    "name":"U.S. Outlying Islands",
      //    "code":"UM",
      //    "emoji":"🇺🇲",
      //    "unicode":"U+1F1FA U+1F1F2",
      //    "image":"https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg"
      // },
      // {
      //    "name":"United Nations",
      //    "code":"UN",
      //    "emoji":"🇺🇳",
      //    "unicode":"U+1F1FA U+1F1F3",
      //    "image":"https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UN.svg"
      // },
      {
         "name": "United States",
         "code": "US",
         "emoji": "🇺🇸",
         "dial_code": "+1",
         "unicode": "U+1F1FA U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
      },
      {
         "name": "Uruguay",
         "code": "UY",
         "emoji": "🇺🇾",
         "dial_code": "+598",
         "unicode": "U+1F1FA U+1F1FE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg"
      },
      {
         "name": "Uzbekistan",
         "code": "UZ",
         "emoji": "🇺🇿",
         "dial_code": "+998",
         "unicode": "U+1F1FA U+1F1FF",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg"
      },
      {
         "name": "Vatican City",
         "code": "VA",
         "emoji": "🇻🇦",
         "unicode": "U+1F1FB U+1F1E6",
         "dial_code": "+379",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg"
      },
      {
         "name": "St. Vincent & Grenadines",
         "code": "VC",
         "emoji": "🇻🇨",
         "dial_code": "+1784",
         "unicode": "U+1F1FB U+1F1E8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg"
      },
      {
         "name": "Venezuela",
         "code": "VE",
         "emoji": "🇻🇪",
         "dial_code": "+58",
         "unicode": "U+1F1FB U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg"
      },
      {
         "name": "British Virgin Islands",
         "code": "VG",
         "emoji": "🇻🇬",
         "dial_code": "+1284",
         "unicode": "U+1F1FB U+1F1EC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg"
      },
      {
         "name": "U.S. Virgin Islands",
         "code": "VI",
         "emoji": "🇻🇮",
         "dial_code": "+1340",
         "unicode": "U+1F1FB U+1F1EE",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg"
      },
      {
         "name": "Vietnam",
         "code": "VN",
         "emoji": "🇻🇳",
         "dial_code": "+84",
         "unicode": "U+1F1FB U+1F1F3",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg"
      },
      {
         "name": "Vanuatu",
         "code": "VU",
         "emoji": "🇻🇺",
         "dial_code": "+678",
         "unicode": "U+1F1FB U+1F1FA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg"
      },
      {
         "name": "Wallis & Futuna",
         "code": "WF",
         "emoji": "🇼🇫",
         "dial_code": "+681",
         "unicode": "U+1F1FC U+1F1EB",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg"
      },
      {
         "name": "Samoa",
         "code": "WS",
         "emoji": "🇼🇸",
         "dial_code": "+685",
         "unicode": "U+1F1FC U+1F1F8",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg"
      },
      {
         "name": "Kosovo",
         "code": "XK",
         "emoji": "🇽🇰",
         "dial_code": "+383",
         "unicode": "U+1F1FD U+1F1F0",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg"
      },
      {
         "name": "Yemen",
         "code": "YE",
         "emoji": "🇾🇪",
         "dial_code": "+967",
         "unicode": "U+1F1FE U+1F1EA",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg"
      },
      {
         "name": "Mayotte",
         "code": "YT",
         "emoji": "🇾🇹",
         "dial_code": "+262",
         "unicode": "U+1F1FE U+1F1F9",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg"
      },
      {
         "name": "South Africa",
         "code": "ZA",
         "emoji": "🇿🇦",
         "dial_code": "+27",
         "unicode": "U+1F1FF U+1F1E6",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg"
      },
      {
         "name": "Zambia",
         "code": "ZM",
         "emoji": "🇿🇲",
         "dial_code": "+260",
         "unicode": "U+1F1FF U+1F1F2",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg"
      },
      {
         "name": "Zimbabwe",
         "code": "ZW",
         "emoji": "🇿🇼",
         "dial_code": "+263",
         "unicode": "U+1F1FF U+1F1FC",
         "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg"
      },
      // {
      //    "name":"England",
      //    "code":"ENGLAND",
      //    "emoji":"🏴󠁧󠁢󠁥󠁮󠁧󠁿",
      //    "unicode":"U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F",
      //    "image":"https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg"
      // },
      // {
      //    "name":"Scotland",
      //    "code":"SCOTLAND",
      //    "emoji":"🏴󠁧󠁢󠁳󠁣󠁴󠁿",
      //    "unicode":"U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F",
      //    "image":"https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg"
      // },
      // {
      //    "name":"Wales",
      //    "code":"WALES",
      //    "emoji":"🏴󠁧󠁢󠁷󠁬󠁳󠁿",
      //    "unicode":"U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F",
      //    "image":"https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg"
      // }
   ]

export const refreshAPI = async () => {

   let res = await axios.get(`${config.api_url}/user/refresh`,
      { headers: { authorization: `Bearer ${localStorage.getItem("auth_token")}`, refresh: `Bearer ${localStorage.getItem("refresh_token")}` } }
   )
   if (res?.data?.user_details?.refresh_token && res?.data?.user_details?.auth_token) {
      localStorage.setItem("refresh_token", res?.data?.user_details?.refresh_token)
      localStorage.setItem("auth_token", res?.data?.user_details?.auth_token)
      const token = jwt_decode(res?.data?.user_details?.auth_token)
      localStorage.setItem("user_name", token?.username)
      localStorage.setItem("user_id", token?.user_id)
   }
   else if (res?.data?.error) {
      localStorage.clear()
   }

};

