import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { LocalStorageKeys } from "../../utils";
import NewCustomForestMap from "../../components/newCustomForestMap/newCustomForestMap";

export const NewHome = (props) => {
  const size = useWindowDimensions()

  const [forest, setForest] = useState({ list: [], center: "" });

  useEffect(() => {
    loadLargeFile()
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      localStorage.setItem(LocalStorageKeys.device, "mobile")
    } else {
      localStorage.setItem(LocalStorageKeys.device, "web")
    }
    // eslint-disable-next-line
  }, []);

  const handleForestClick = async (data) => {
    console.log(data);
  }

  const handleTreeClick = async (data) => {
    console.log(data);
  }

  const loadLargeFile = async () => {
    const response = await fetch('/bth_forest.json');
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let data = '';
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      data += decoder.decode(value, { stream: true });
    }

    const final = JSON.parse(data)

    setForest({
      list: final?.forestList,
      center: final?.initialCenter,
    });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}>
        {forest.list?.length > 0 &&
          <NewCustomForestMap
            size={size}
            searchValue={props?.searchValue}
            forestList={forest.list}
            initialCenter={forest.center}
            handleForestClick={handleForestClick}
            handleTreeClick={handleTreeClick}
          />}
      </div>
    </>
  );
};
