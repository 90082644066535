
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  
    otpTitle: {
        color: "#000000",
        fontFamily: "crayond_medium",
        fontSize: "14px",
    },
    saveBtn: {
        backgroundColor: '#71BC44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 40,
        "&:hover": {
        backgroundColor: '#71BC44',
        }

    },
    bottomFixBtn: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: "24px"
        // top:0
    },
    otp: {
        boxShadow: "none",
        "& input": {
          boxShadow: "none",
          height: "48px",
          width: "50px !important",
          background: theme.palette.common.white,
          borderRadius: 8,
          fontSize: "1.125rem",
          fontFamily: 'crayond_bold',
          border: `1.5px solid #E4E8EE`,
          "&:focus": {
            border: `1.5px solid ${theme.palette.primary.main}`,
            outline: "none",
          },
        },
      },
      outlineBtn: {
        border: "1px solid #E4E8EE",
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height:40,
        cursor:'pointer',
        color:"#000000",
        "&:hover": {
            backgroundColor: '#ffffff',
        border: "1px solid #E4E8EE",

            }
    },
}
))