
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    saveBtn: {
        backgroundColor: '#71BC44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 40,
        "&:hover": {
            backgroundColor: '#71BC44',
        }

    },
    imageDiv: {
        backgroundColor: "#021B1C",
        padding: 16,
        opacity: "75%",
        height: "100px",
        position: 'absolute',
        bottom: 25,
        left: '25%',
        right: '25%',
        borderRadius: "12px"
    },
    imgTextPetName: {
        fontFamily: 'crayond_bold',
        fontSize: "16px",
        color: "white",
        textAlign: 'center'
    },
    imgText: {
        fontFamily: 'crayond_medium',
        fontSize: "14px",
        color: "white",
        textAlign: 'center'
    },
    imgTextDiv: {
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bottom: "-6px",
    },
    detailsBox:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%"
    }
}
))