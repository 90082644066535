import React, { useRef, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import PhotoIcon from '@mui/icons-material/Photo';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

export const CustomCamera = ({
    size = "",
    updateState = () => false,
    handleCameraOpen = () => false,
    handleOpenPlantSaplingForm = () => false,
}) => {
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [isTorchOn, setIsTorchOn] = useState(false);
    const [facingMode, setFacingMode] = useState("environment");
    const fileInputRef = React.useRef();

    // Start the camera stream
    const startCamera = async (mode = "environment") => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: mode } });
            setStream(mediaStream);
            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    // Stop the camera stream
    const stopCamera = () => {
        if (videoRef.current?.srcObject) {
            const stream = videoRef.current.srcObject;
            stream.getTracks().forEach((track) => track.stop());
            videoRef.current.srcObject = null;
        }
        setStream(null);
    };

    // Flip the camera
    const flipCamera = () => {
        stopCamera();
        const newFacingMode = facingMode === "environment" ? "user" : "environment";
        setFacingMode(newFacingMode);
        startCamera(newFacingMode);
    };

    // Toggle Flash/Torch
    const toggleFlash = async () => {
        if (stream) {
            const videoTrack = stream.getVideoTracks()[0];
            const capabilities = videoTrack.getCapabilities();

            // Check if the device supports torch mode
            if (capabilities.torch) {
                const settings = videoTrack.getSettings();
                const isTorchEnabled = settings.torch || false;

                try {
                    await videoTrack.applyConstraints({
                        advanced: [{ torch: !isTorchEnabled }],
                    });
                    setIsTorchOn(!isTorchEnabled);
                } catch (error) {
                    console.error('Error toggling torch:', error);
                }
            } else {
                console.warn('Torch mode is not supported on this device');
            }
        }
    };

    // Capture the current frame
    const capturePhoto = () => {
        const canvas = document.createElement('canvas');
        const video = videoRef.current;

        if (video) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageUrl = canvas.toDataURL('image/jpeg');
            canvas.toBlob(
                (blob) => {
                    const file = new File([blob], `tree-sapling-${Date.now()}.jpeg`, {
                        type: 'image/jpeg',
                        lastModified: Date.now(),
                    });
                    updateState("rawImage", { file, imageUrl })
                },
                'image/jpeg', // Specify the image format
                1 // Quality parameter (1 = maximum quality)
            );
            handleOpenPlantSaplingForm()
            handleCameraOpen(false)
            stopCamera()
        }
    };

    useEffect(() => {
        startCamera();
        return () => stopCamera();
    }, []);

    const handleFileClick = () => {
        fileInputRef.current.click(); // Trigger file input click when icon is clicked
    };
    const handleImageUpdate = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            updateState("rawImage", { file, imageUrl })
            handleOpenPlantSaplingForm()
            handleCameraOpen(false)
            stopCamera()
        }
    };
    return (
        <div style={{ position: 'relative', height: size?.height, overflow: 'hidden' }}>
            <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    backgroundColor: "#00000096",
                }}
            ></video>

            <div
                style={{
                    position: 'absolute',
                    padding: "20px",
                    zIndex: 10,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box onClick={() => {
                    handleCameraOpen(false)
                    stopCamera()
                }}
                    sx={{
                        width: "40px", height: "40px", borderRadius: "50%", padding: "6px", backgroundColor: "#00000096",
                        display: "flex", justifyContent: "center", alignItems: "center",
                    }}>
                    <CloseIcon htmlColor='white' />
                </Box>
                <Box onClick={toggleFlash}
                    sx={{
                        width: "40px", height: "40px", borderRadius: "50%", padding: "6px", backgroundColor: "#00000096",
                        display: "flex", justifyContent: "center", alignItems: "center",
                    }}>
                    {isTorchOn ? <FlashOnIcon htmlColor='white' /> : <FlashOffIcon htmlColor='white' />}
                </Box>
            </div>
            {/* Capture button overlay */}
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    padding: "30px",
                    zIndex: 10,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box onClick={handleFileClick}
                    sx={{
                        width: "60px", height: "60px", borderRadius: "50%", padding: "6px", backgroundColor: "#00000096",
                        display: "flex", justifyContent: "center", alignItems: "center",
                    }}>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageUpdate}
                        style={{ display: "none" }}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                    />
                    <PhotoIcon htmlColor='white' />
                </Box>
                <Box onClick={capturePhoto}
                    sx={{
                        width: "80px", height: "80px", border: "4px solid white", borderRadius: "50%", padding: "6px",
                        display: "flex", justifyContent: "center", alignItems: "center",
                    }}>
                    <Box
                        sx={{
                            width: "100%", height: "100%", backgroundColor: "white", borderRadius: "50%",
                        }} />
                </Box>
                <Box onClick={flipCamera}
                    sx={{
                        width: "60px", height: "60px", borderRadius: "50%", padding: "6px", backgroundColor: "#00000096",
                        display: "flex", justifyContent: "center", alignItems: "center",
                    }}>
                    <FlipCameraAndroidIcon htmlColor='white' />
                </Box>
            </div>
        </div>
    );
};