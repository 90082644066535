import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import { Label } from "../label";

const useStyles = makeStyles((theme) => ({
  createBtn: {
    display: "inline-flex",
    cursor: "pointer",
  },
  selects: {
    "& .css-1rhbuit-multiValue": {
      backgroundColor: window.location.pathname === "/properties" ? "#E4E8EE" : "#DBEDFE",
    },
  },
  label: {
    color: theme.typography.color.primary,
    fontFamily: 'crayond_medium',
    fontSize:"0.875rem",
    marginBottom: "5px",
  },
  labelb: {
    color: theme.typography.color.primary,
    fontSize:"1rem",
    fontFamily: 'crayond_bold',
    marginBottom: "5px",
  },
  customOption: { padding: "0px !important", backgroundColor: "transparent" },
  selectRoot: {
    border: (props) => props?.style || props?.noBorder ? "none" : "1.5px solid #E4E8EE",

    boxShadow: "none",
    outline: "none",
    display: 'flex',
    alignItems: (props)=> props?.alignItem ?? "center",
    backgroundColor: (props) => props?.color ?? "none",
    borderRadius: (props) => props?.borderRadius ? 4 : 0,
    "&:hover": {
      border: (props) => props?.style || props?.noBorder ? "none" : "1.5px solid #E4E8EE",

    },
  }
}));


export const SelectBox = ({
  parentID = "",
  label = "",
  isRequired = false,
  isPaginate = false,
  isReadOnly = false,
  createSelect = false,
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
  isError = false,
  errorMessage = "",
  isMulti = false,
  reduceOptions = undefined,
  debounceTimeout = 0,
  key,
  bold = false,
  isSelectAll,
  color,
  padding,
  isSearchable = true,
  width = "auto",
  style = false,
  isClearable = false,
  menuPlacement = "auto",

  zIndex = 0,
  borderRadius = true,
  noBorder = false,
  prefix = false,
  selectHeight = null,
  fontFamily = 'crayond_medium',
  left = "0px",
  paddingLeft=null,
  customOptionComponent = false,
  menuOptionHeight = null,
  textColor = '#333333',
  textTrasfrom = "auto",
  top = "auto",
  noSearch = false,
  iconColor="#cccccc",
  menu_text_transfrom,
  labelStyle="",
  zeroMargin = false,
  info = "",
  handleInfoClick = () => false,
  alignItem="center"
}) => {
  const classes = useStyles({
    bold,
    isSelectAll,
    style,
    noBorder,
    borderRadius,
    alignItem
  });
  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        {/* {!noSearch && <Box marginInlineStart={zeroMargin?"0px":"12px"}><SerachIMG color={"#98A0AC"} /></Box>} */}

        {children}
        <Box sx={{padding: "0px 8px 0px 0px"}}><KeyboardArrowDownIcon sx={{color: textColor}} /></Box>
      </components.Control>
    );
  };


  const customStyles = {

    control: (base) => ({
      ...base,
      textTransform: menu_text_transfrom,
      borderRadius: borderRadius ? 4 : 0,
      backgroundColor: "transparent",
      zIndex: zIndex ?? 1,
      height: selectHeight ? selectHeight : "45px !important",
      fontSize:"0.875rem",
      padding: padding ? padding : "auto",
      minHeight: selectHeight ? selectHeight : "45px !important",

      fontFamily: fontFamily,
      minWidth: width,
      border: "none",
      boxShadow: "none",
      outline: "none",
      "& div": {
        "& .singleValue": {
          color: `${textColor} !important`,
          textTransform: `${textTrasfrom} !important`,

          fontFamily: fontFamily,
          fontSize:"0.875rem",

        }
      },
      "&:hover": {
        border: "none"
      },
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        fontFamily: fontFamily,
        fontSize:"0.875rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
        overflow: "hidden",
        color: "#b4b4b4",
        textTransform: menu_text_transfrom,

      };
    },
    menu: defaultStyles => {
      return {
        ...defaultStyles,
        zIndex: 999,
        borderRadius: "4px",
        fontSize:"0.875rem",
        fontFamily: fontFamily,
        textTransform: menu_text_transfrom,
      };
    },
    dropdownIndicator: base => ({
      ...base,
      color: iconColor // Custom colour
    })
  };

  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props} className={classes.customOption}>
        {customOptionComponent({ ...props })}
      </components.Option>
    );
  };

  return (

    <div className={classes.root}>
      {label && (<Stack direction={"row"} columnGap={"8px"} justifyContent={"start"} alignItems={"start"}>
        <Label label={label} style={labelStyle} isRequired={isRequired} />
        {/* {Boolean(info) && (
          <InfoIcon
            info={info}
            onClick={handleInfoClick}
            placement={"right"}
            toolTipStyle={{ color: "#98A0AC" }} />
        )} */}
      </Stack>)}
      <Box className={classes.selectRoot} style={{ backgroundColor: color }}>


        <Grid container spacing={1} alignItems={alignItem}>
          {
            prefix &&
            <Grid item xs={1} mt={0.5} sx={{ paddingLeft: "16px !important" }}>
              <Box style={{ paddingLeft: left ?? "0px", paddingTop: top ?? 'auto' }}>

                {
                  prefix
                }
              </Box>
            </Grid >
          }
          <Grid item xs={prefix ? 11 : 12} sx={{ paddingLeft: paddingLeft??"16px !important" }}>
            {isPaginate ? (
              <AsyncPaginate
                maxMenuHeight={menuOptionHeight ? menuOptionHeight : '150px'}

                key={key}
                isClearable={isClearable}
                isSearchable={isSearchable}
                components={
                  customOptionComponent ?
                    {
                      IndicatorSeparator: () => null,
                      Option,
                      Control,
                      DropdownIndicator: () => null


                    } :
                    {
                      IndicatorSeparator: () => null,
                    }
                }
                value={value}
                placeholder={placeholder}
                loadOptions={loadOptions}
                onChange={(_)=>{
                  onChange(_)}}
                options={options}
                isLoading={loading}
                defaultOptions={options}
                styles={customStyles}
                isDisabled={isReadOnly}
                isMulti={isMulti}
                debounceTimeout={debounceTimeout}
                reduceOptions={reduceOptions}
                menuPlacement={menuPlacement}
                menuPortalTarget={document.getElementById(parentID)}
                minMenuHeight={"150px"}

              />
            ) : createSelect ? (
              <CreatableSelect
                isClearable={false}
                options={options}
                isLoading={loading}
                placeholder={placeholder}
                components={
                  customOptionComponent ?
                    {
                      IndicatorSeparator: () => null,
                      Option,
                    } :
                    {
                      IndicatorSeparator: () => null,
                    }
                }
                value={value}
                onChange={onChange}
                styles={customStyles}
                isDisabled={isReadOnly}
                menuPlacement={menuPlacement}
                menuPortalTarget={document.getElementById(parentID)}
                maxMenuHeight={menuOptionHeight ? menuOptionHeight : '150px'}

              />
            ) : (
              <Select
                isClearable={false}
                isSearchable
                components={
                  customOptionComponent ?
                    {
                      IndicatorSeparator: () => null,
                      Option,
                    } :
                    {
                      IndicatorSeparator: () => null,
                    }
                }
                value={value}
                autoComplete="off"
                placeholder={placeholder}
                options={options}
                isLoading={loading}
                menuPlacement={menuPlacement}
                onChange={onChange}
                styles={customStyles}
                className={classes.selects}
                isMulti={isMulti}
                isDisabled={isReadOnly}
                isSelectAll
                menuPortalTarget={document.getElementById(parentID)}
                maxMenuHeight={menuOptionHeight ? menuOptionHeight : '150px'}
                minMenuHeight={"150px"}


              />
            )}
          </Grid>
        </Grid >
      </Box >

      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </div >
  );
};
