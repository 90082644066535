import makeStyles from "@mui/styles/makeStyles";


export const useStyles = makeStyles((theme) => ({
  label: {
    color: (props) => props?.labelColor ?? theme.typography.color.primary,
    fontFamily: (props) => (props?.fontFamily === "bold" ? 'crayond_bold' : 'crayond_medium'),
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "8px",
    textAlign: (props) => props?.textAlign ?? "-webkit-match-parent",

  },
  required: {
    color: "red",
    fontFamily: 'crayond_medium',
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
}));
