
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    cardBox: {
        backgroundColor: "#ffffff",
        border: '1px solid #E4E8EE',
        borderRadius: '8px'
    },
    storeName: {
        color: "#000000",
        fontFamily: "crayond_medium",
        fontSize: "16px",
    },
    storeImage: {
        borderRadius: '8px',
        width: 50,
        height: 50
    },
    distance: {
        color: "#000000",
        fontFamily: "crayond_medium",
        fontSize: "14px",
    },
    outlineBtn: {
        border: "1px solid #E4E8EE",
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height:40,
        cursor:'pointer'
    },
    directionsText:{
        color: "#000000",
        textTransform: 'capitalize',
        fontFamily: "crayond_medium",
        fontSize: "14px",
    },
    containedBtn: {
        backgroundColor: '#71BC44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height:40,
        cursor:'pointer'

    },
    callText: {
        color: "#ffffff",
        textTransform: 'capitalize',
        fontFamily: "crayond_medium",
        fontSize: "14px",
    },
    address:{
        color: "#000000",
        fontFamily: "crayond_regular",
        fontSize: "14px",   
    }
}
))