import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Label } from "../label";
import './style.css';
import { differenceInDays } from "date-fns";
const styles = makeStyles((theme) => ({
      placeholder: {
            fontFamily: 'crayond_medium',
            fontSize: "0.875rem",
            whiteSpace: "nowrap",
            color: "#b4b4b4"
      },
      // div: {
      //     min: "300px"
      // },
      customPlaceholder: {
            fontSize: "0.75rem",
            color: "#4E5A6B",
            textAlign: "left",
            whiteSpace: "nowrap"
      },
      title: {
            fontFamily: 'crayond_medium',
            fontSize: "0.75rem",
            color: "#4E5A6B",
            marginBottom: "8px"
      },
      type: {
            fontFamily: 'crayond_medium',
            fontSize: "0.75rem",
            color: "#4E5A6B",
            backgroundColor: "#F2F4F7",
            borderRadius: theme.palette.borderRadius,
            padding: "4px 8px",
            textAlign: "center",
            marginRight: "4px",
            marginLeft: "4px",
            cursor: "pointer"

      },
      textField: {
            width: "70px",
            padding: "4px",
            [`& fieldset`]: {
                  borderRadius: theme.palette.borderRadius,
                  height: (props) => (props.multiline ? "unset" : 32),
                  border: "1px solid #E4E8EE",
                  "& :hover": {
                        border: "1px solid #5078E1",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: "11px 14px",
            },
      },
      dialog: {
            "& .MuiPopover-paper": {
                  borderRadius: theme.palette.borderRadius,
                  boxShadow: "0px 8px 24px #0717411F"
            }
      },
      typea: {
            fontFamily: 'crayond_medium',
            fontSize: "0.75rem",

            borderRadius: theme.palette.borderRadius,
            padding: "4px 8px",
            textAlign: "center",
            marginRight: "4px",
            marginLeft: "4px",
            cursor: "pointer",
            backgroundColor: "#5078E1",
            color: "white"
      },
      value: {
            fontFamily: (props) => props?.fontFamily ?? 'crayond_medium',
            fontSize: (props) => props?.fontSize ?? "0.875rem",
            color: "#091B29",
      },
      customValue: {
            fontFamily: 'crayond_bold',
            fontSize: "0.875rem",
            color: "#091B29",
            textAlign: "-webkit-auto"
      },
      main: {
            border: "1px solid #E4E8EE",
            borderRadius: '4px'
      },
      noneBorder: {
            border: "none",
            borderRadius: '4px'

      },
      Label: {
            color: theme.typography.color.tertiary,
            fontFamily: 'crayond_medium',
            fontSize: "0.875rem",
            marginBottom: "5px",
      },
}));
export const DatePickerNew = ({
      startIcon = "",
      placeholder = "",
      handleChange = () => false,
      value = "",
      label = "",
      isrequired,
      isError = '',
      errorMessage = "",
      isReadonly = false,
      range = false,
      rangeValue = "",
      endDate = "",
      minDate = null,
      maxDate = null,
      height = "45px",
      margintop = "0px",
      noBorder = "",
      customValue = "",
      changePlaceHolder = false,
      fontFamily = 'crayond_medium',
      padding = "10px",
      fontSize = "0.875rem",
      is_full_year = false,
      isShowDifference = false
}) => {
      const classes = styles({ fontFamily, fontSize })
      const [anchorEl, setAnchorEl] = React.useState(null);


      const handleClick = (event) => {
            if (!isReadonly) {
                  setAnchorEl(event.currentTarget);
            }
      };

      const handleClose = () => {
            setAnchorEl(null);
      };

      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;
      return (

            <div style={{ marginTop: margintop }}>
                  {label && <Label label={label} isRequired={isrequired} />}
                  <div style={{ backgroundColor: isReadonly ? "#F2F4F7" : "auto" }} className={noBorder ? classes.noneBorder : classes?.main}>
                        <Box sx={{ cursor: "pointer", position: 'relative', padding: padding ? padding : changePlaceHolder ? '10px 4px' : '10px' }} display="flex" alignItems="center" height={height} onClick={handleClick}>

                              <Box flexGrow={1} marginLeft="4px">
                                    {
                                          value ?
                                                range ?
                                                      <Typography className={customValue ? classes.customValue : classes.value}>
                                                            &#x202a;{moment(value).format("DD MMM YY")}&#x202c; - {moment(endDate).format("DD MMM YY")} ({moment(endDate).diff(value, "days") + 1} Days)
                                                      </Typography>
                                                      :

                                                      <Typography className={customValue ? classes.customValue : classes.value}>
                                                            &#x202a;{is_full_year ? moment(value).format("DD MMM YYYY") : moment(value).format("DD MMM YY")}&#x202c;
                                                      </Typography>
                                                : <Typography className={changePlaceHolder ? classes.customPlaceholder : classes.placeholder}>
                                                      {placeholder}</Typography>
                                    }
                              </Box>
                              <Stack direction="row" spacing={1} alignItems={"center"} marginTop="4px" marginRight="8px">
                                    {isShowDifference &&
                                          <Typography sx={{ fontSize: 14, fontFamily: 'crayond_regular',color:'#000000' }}>{differenceInDays(new Date(), new Date(value)) > 1 ? `${differenceInDays(new Date(), new Date(value))} days old` : differenceInDays(new Date(), new Date(value)) === 1 ? '1 day old' : ""}</Typography>
                                    }
                                    <img src={'/images/date_picker_icon.svg'} alt="" />
                              </Stack>

                        </Box>
                        <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                              }}
                              className={classes.dialog}
                        >
                              <div className={classes.div}>
                                    <Box p={1.4}>


                                          <Stack
                                                direction="row"
                                                divider={<Divider orientation="vertical" flexItem />}
                                                spacing={2}
                                          >
                                                <div>
                                                      <Typography className={classes.title}>{label}</Typography>

                                                      <DatePicker
                                                            //  dateFormat="MM-DD-YYYY"
                                                            selected={value}
                                                            onChange={(value) => {
                                                                  handleChange(value)
                                                                  setAnchorEl(null)
                                                            }}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            disabled
                                                            dropdownMode="select"
                                                            selectsDisabledDaysInRange
                                                            inline
                                                            minDate={minDate ?? false}
                                                            maxDate={maxDate ?? false}
                                                      //showMonthDropdown
                                                      />
                                                </div>


                                          </Stack>



                                    </Box>


                              </div>
                        </Popover>
                  </div>
                  {isError && (
                        <Typography variant={"caption"} color={"error"}>
                              {errorMessage}
                        </Typography>
                  )}
            </div>
      )
}