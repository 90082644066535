import { Avatar, Box, Button, Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material"
import React from "react"
import { useStyles } from "./style"
import { stringAvatar } from "../../utils"
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Call } from "@mui/icons-material";
import DirectionsIcon from '@mui/icons-material/Directions';
export const StoreCard = ({ data = {} }) => {
    const matches = useMediaQuery('(min-width: 768px)')
    const openMaps = () => {
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${data?.latitude},${data?.longitude}&travelmode=driving`;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.location.href = mapsUrl;
        } else {
            window.open(mapsUrl, '_blank');
        }
    };

    const classes = useStyles()
    return (
        <Box className={classes?.cardBox} mb={1}>
            <Stack direction="row" spacing={1} alignItems="center" p={1.5}>
                <Avatar variant="square" className={classes?.storeImage} {...stringAvatar(data?.name)} />
                <Box>
                    <Typography className={classes?.storeName}>{data?.name}</Typography>
                    {
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <PlaceOutlinedIcon sx={{ fontSize: 16 }} />
                            <Typography className={classes?.distance}>{`${data?.distance ? data?.distance?.toFixed(2) : `0`} km, `}</Typography>
                            {data?.address !== null && <Typography sx={{textOverflow: "ellipsis",overflow:'hidden',maxWidth:matches?"230px":"170px",whiteSpace:'nowrap'}} className={classes?.address}>{data?.address?.door_no ? data?.address?.door_no + ", " : ""}{data?.address?.address_1 ? data?.address?.address_1 + ", " : ""}{data?.address?.city ? data?.address?.city + ", " : ""}{data?.address?.pincode ? data?.address?.pincode + "." : ""}</Typography>}
                        </Stack>
                    }
                </Box>
            </Stack>
            <Grid container spacing={1} alignItems="center" p={1.5}>
                <Grid item xs={6} sm={6}>
                    <a href={`tel:${data?.contact}`} style={{ textDecoration: 'none' }}>
                        <Stack direction={"row"} spacing={0.5} alignItems="center" className={classes.containedBtn}>
                            <Call sx={{ color: "#FFFFFF", fontSize: 20 }} />
                            <Typography className={classes.callText}>Call</Typography>
                        </Stack>
                    </a>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Stack direction={"row"} spacing={0.5} alignItems="center" className={classes.outlineBtn}
                        onClick={openMaps}>
                        <DirectionsIcon sx={{ color: "#000000", fontSize: 24 }} />
                        <Typography className={classes.directionsText}>Get Directions</Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}