import { Box, Button } from "@mui/material"
import React from "react"
import { TextBox } from "../../components/textbox"
import { config } from "../../config"
import { NetworkCall } from "../../networkcall"
import { NetWorkCallMethods } from "../../utils"
import { useStyles } from "./style"


const NewLoginScreen = ({ setIsRegister = () => false, setShowLogin = () => false, loginData = "", updateState = () => false, setIsVerifyOTP = () => false }) => {
    const classes = useStyles()


    const onSendOTP = () => {
        const payload = {
            "mobile_no": loginData?.mobile_no?.mobile ?? null,
            "mobile_country_code": (loginData?.mobile_no?.mobile_code).slice(1) ?? null
        }
        NetworkCall(
            `${config.api_url}/user/login`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            setShowLogin(false)
            setIsVerifyOTP(true)
        }).catch((err) => {
            if (err?.response?.data?.message === "User not found") {
                setShowLogin(false)
                setIsRegister(true)
            }
        })

    }
    return (
        <Box p={1}>
         <TextBox
                label="Mobile Number"
                placeholder="Enter Number"
                value={loginData?.mobile_no?.mobile}
                startAdornment={"+91"}
                onChange={(e) => updateState("mobile_no", e?.target?.value)}
            />
            <Box className={classes.bottomFixBtn}>
                <Button variant="contained" fullWidth className={classes.saveBtn} onClick={() => onSendOTP()} disabled={loginData?.mobile_no?.mobile?.length === 10 ? false : true}>Send OTP</Button>
            </Box>

        </Box>
    )
}
export default NewLoginScreen