import { Edit } from "@mui/icons-material"
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { Box, Typography, useMediaQuery } from "@mui/material"
import React, { useState } from "react"
import { loadOptionsApis, LocalStorageKeys, useWindowDimensions } from "../../utils"
import { DatePickerNew } from "../datepickernew"
import { SelectBox } from "../select"
import { TextBox } from "../textbox"
import { useStyles } from "./style"

export const PlantSaplingForm = ({
    data = "",
    address = "",
    updateState = () => false,
    handleCameraOpen = () => false,
}) => {
    const classes = useStyles()
    const matches = useMediaQuery('(min-width: 768px)')
    const [isImageHover, setIsImageHover] = useState(false)
    const size = useWindowDimensions()
    const manualResponse = (array) => {
        const result = array?.map((x) => {
            return {
                ...x,
                value: x?.id,
                label: x?.common_name
            }
        })
        return result
    }

    // Handle file selection
    const handleImageUpdate = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            updateState("rawImage", { file, imageUrl })
        }
    };
    return (
        <Box p={"20px"} sx={{ height: matches ? 656 : size?.height - 150, overflow: 'overlay' }}>
            {data?.displayImage
                ? (<Box position={'relative'}>
                    <Box className={classes.imageBox} sx={{ position: 'relative' }} onMouseOver={() => setIsImageHover(true)} onMouseLeave={() => setIsImageHover(false)}>
                        <img src={data?.displayImage} className={classes.saplingImg} alt="sapling img" />
                        {localStorage.getItem(LocalStorageKeys?.device) === "mobile"
                            ? <Box className={classes.hoverImageBox}>
                                <Box className={classes.editbox} onClick={() => handleCameraOpen(true)}>
                                    <Edit sx={{ color: "#71BC44", fontSize: 18 }} />
                                </Box>
                            </Box>
                            : (isImageHover &&
                                <Box className={classes.hoverImageBox}>
                                    <Box className={classes.editbox}>
                                        <input
                                            className={classes.uploadInput}
                                            type="file"
                                            name="img"
                                            accept="image/*"
                                            onChange={(value) => {
                                                handleImageUpdate(value);
                                            }}
                                            onClick={(event) => {
                                                event.target.value = null;
                                            }}
                                            id="uplodebtn"
                                        />
                                        <Edit sx={{ color: "#71BC44", fontSize: 18 }} />
                                    </Box>
                                </Box>)
                        }
                    </Box>
                        <Box className={classes.petnameBox}>
                            <TextBox
                                border={"none"}
                                backgroundColor="#021b1c99"
                                placeHolderTextColor="white"
                                placeHolderTextOpacity={0.85}
                                textColor={"white"}
                                borderRadius="8px"
                                label=""
                                placeholder="Enter Pet Name"
                                autoFocus={data?.displayImage?.length > 0 ? true : false}
                                value={data?.petname}
                                textAlign='center'
                                onChange={(e) => updateState("petname", e?.target?.value)}
                            />
                        </Box>
                </Box>)
                : (<Box position={"relative"}>
                    <Box className={[classes.container, classes.uploadContainer]}>
                        <input
                            className={classes.uploadInput}
                            type="file"
                            name="img"
                            accept="image/*"
                            onChange={(value) => {
                                handleImageUpdate(value);
                            }}
                            onClick={(event) => {
                                event.target.value = null;
                            }}
                            id="uplodebtn"
                        />
                        <AddPhotoAlternateIcon fontSize="large" htmlColor="#71BC44" />
                        <Typography className={classes.uplaodTitle}>Upload an Image</Typography>
                    </Box>
                </Box>)
            }
            {data?.error?.rawImage?.length > 0 ?
                    <Box width={"100%"} mt={1} sx={{ textAlign: "center" }}>
                        <Typography width={"100%"} sx={{ textAlign: "center" }} variant={"caption"} color={"error"}>* Image is required</Typography>
                    </Box>
                    : data?.error?.petname?.length > 0 ?
                        <Box width={"100%"} mt={3} sx={{ textAlign: "center" }}>
                            <Typography width={"100%"} sx={{ textAlign: "center" }} variant={"caption"} color={"error"}>* Pet Name is required</Typography>
                        </Box>
                        : ""
            }


            <Box mt={4}>
                <TextBox
                    label="Location"
                    value={address}
                    isReadonly
                />
            </Box>

            <Box mt={1}>
                <SelectBox
                    onChange={(value) => updateState('species', value)}
                    value={data?.species}
                    label="Species Name"
                    placeholder="Choose Species Name"
                    loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                            "get-stores/get-plant-species",
                            {},
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            manualResponse,
                        )
                    }
                    isRequired
                    isError={data?.error?.species?.length > 0}
                    errorMessage={data?.error?.species}
                    isPaginate={true}
                    debounceTimeout={800}
                />
            </Box>
            <Box mt={1}>
                <DatePickerNew
                    label={"Planted on"}
                    handleChange={(value) => updateState("planted_on", value)}
                    value={data?.planted_on}
                    maxDate={new Date()}
                    isShowDifference={true}
                />
            </Box>

        </Box>
    )
}