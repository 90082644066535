import React from "react";
import { NewHome } from './newHome';
import { withNavBars } from "./../../HOCs";

class NewHomeParent extends React.Component {
  render() {
    return <NewHome {...this.props} />;
  }
}

export default withNavBars(NewHomeParent);
