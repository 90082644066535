import { Box, Button, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LocalStorageKeys } from "../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "absolute",
        flexFlow: "nowrap",
        zIndex: 100,
        bottom: 0,
        width: "100%",
        justifyContent: "center",
        alignitems: "center",
        pointerEvents: "none",
    },
    floatingButton: {
        width: "550px",
        [theme.breakpoints.down("550")]: {
            width: "100%",
        },
        padding: "16px",
    },
    getSaplingButton: {
        width: "200px",
        [theme.breakpoints.down("550")]: {
            width: "100%",
        },
        height: "45px",
        pointerEvents: "auto",
        fontWeight: "bold",
        borderRadius: "8px",
        border: "1px solid #17970E",
        color: "#17970E",
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white"
        },
        textTransform:"none"
    },
    plantSaplingButton: {
        width: "200px",
        [theme.breakpoints.down("550")]: {
            width: "100%",
        },
        height: "45px",
        pointerEvents: "auto",
        fontWeight: "bold",
        borderRadius: "8px",
        border: "1px solid #17970E",
        color: "white",
        backgroundColor: "#17970E",
        "&:hover": {
            backgroundColor: "#17970E"
        },
        textTransform:"none"
    },
}));

export const MapActionButton = ({
    handleOpenStoreList = () => false,
    handleOpenPlantSaplingForm = () => false,
    handleCameraOpen = () => false,
    size = "",
}) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}
            direction={"row"} columnGap={"10px"}
            justifyContent={"center"} alignItems={"center"}>
            <Stack className={classes.floatingButton}
                direction={"row"} columnGap={"10px"}
                justifyContent={"center"} alignItems={"center"}>
                <Button className={classes.getSaplingButton}
                    fullWidth={size?.width < 550}
                    variant="contained"
                    onClick={handleOpenStoreList}>
                    {"Get Sapling(s)"}
                </Button>
                <Button className={classes.plantSaplingButton}
                    fullWidth={size?.width < 550}
                    variant="contained"
                    onClick={() => localStorage.getItem(LocalStorageKeys?.device) === "mobile" ? handleCameraOpen(true) : handleOpenPlantSaplingForm()}>
                    {"Plant a sapling"}
                </Button>
            </Stack>
        </Box>
    )
}