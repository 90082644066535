import { ArrowBack } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Hidden } from "@mui/material";
import * as React from "react";
import { DialogDrawer_Style } from "./style";
export const DialogDrawer = (props) => {
    return (
        <div>
            <Hidden smDown>
                <Dialog
                    open={props?.open}
                    onClose={props?.onClose}
                    aria-labelledby="alert-dialog-title"
                    sx={DialogDrawer_Style?.dialog}
                    maxWidth={props?.maxWidth ? props?.maxWidth : props?.width ?? "xs"}
                    fullWidth
                    fullScreen={props?.fullScreen}
                    aria-describedby="alert-dialog-description"
                >
                    {
                        !props?.isnotTitle &&
                        <DialogTitle sx={DialogDrawer_Style?.header}>
                            <span>{props?.header}</span>{" "}
                            <img
                                src="/images/close.svg"
                                alt="close"
                                onClick={props?.onClose}
                                style={{ cursor: 'pointer' }}
                            />
                        </DialogTitle>
                    }

                    <DialogContent style={{ padding: "0 !important" }}>
                        <Box style={{ height: props?.height ?? "auto" }} overflow="auto"
                            padding={props?.padding ?? 2} sx={{ ...DialogDrawer_Style.component, ...props?.height_style }}>
                            {props?.component ?? props?.dialogComponent}
                        </Box>
                    </DialogContent>
                    {props?.footer &&
                        <DialogActions>

                            <Box sx={DialogDrawer_Style?.footer}>
                                {props?.footer}
                            </Box>

                        </DialogActions>
                    }


                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor="bottom"
                    sx={DialogDrawer_Style?.drawer}
                    open={props?.open}
                    onClose={props?.onClose}
                >
                    {
                        !props?.isnotTitle &&
                        <DialogTitle sx={DialogDrawer_Style?.header}>
                            <Box onClick={props?.onClose}
                                style={{ cursor: 'pointer' }}>
                                <ArrowBack />
                            </Box>
                            <span>{props?.header}</span>{" "}
                            <Box></Box>
                            {/* <img
                                src="/images/close.svg"
                                alt="close"
                                onClick={props?.onClose}
                                style={{ cursor: 'pointer' }}
                            /> */}
                        </DialogTitle>
                    }

                    <Box  overflow="auto" padding={props?.padding ?? 2} sx={{ ...DialogDrawer_Style.component, ...props?.height_style }}>
                        {props?.component ?? props?.drawerComponent}
                    </Box>
                    {props?.footer &&
                        <DialogActions>

                            <Box sx={DialogDrawer_Style?.footer}>
                                {props?.footer}
                            </Box>

                        </DialogActions>
                    }

                </Drawer>
            </Hidden>
        </div >
    );
}
