import {
  APIProvider,
  Map,
  Marker,
  useMap,
} from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer"; // Import the marker clusterer
import React, { useEffect, useRef, useState } from "react";
import { Polygon } from "./polygon.tsx";
import Stas from "../map/stasHeader.js";
import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled';
import Logo from '../../assets/logo.png';
import useWindowDimensions from "../../utils/useWindowDimensions.js";
import makeStyles from '@mui/styles/makeStyles';
import { Alert, Snackbar } from "@mui/material";
import { MapActionButton } from "./mapActionButton.js";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid black",
    borderRadius: "4px",
    backgroundColor: "white",
    color: "black",
    fontSize: "16px",
    fontWeight: "bold",
  }
}))

const CustomForestMap = (props) => {
  const {
    forestDetails,
    searchValue,
    forestList = [],
    initialCenter = "",
    handleForestClick = () => false,
    handleTreeClick = () => false,
    handleOpenStoreList = () => false,
    handleOpenPlantSaplingForm = () => false,
    handleCameraOpen = () => false,
    handlePlantSapling = () => false,
    size = "",
  } = props;
  const isMyForest = JSON.parse(localStorage.getItem("is_myForest"))
  const classes = useStyles();
  const google_api_key = "AIzaSyC460AqqU6PgMO4s5wJvE5GLge9evj4r6o";
  const [zoom, setZoom] = useState(10);
  const [isInitialTriggered, setIsInitialTriggered] = useState(false);
  const [currentpos, setcurrentpos] = React.useState(initialCenter);
  const mapRef = useRef(null);
  const markerClustererRef = useRef(null);
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const CustomSkeleton = styled(Skeleton)({
    minHeight: size?.height - 64,
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'unset',
    backgroundColor: "#fff",
    '& img': {
      visibility: "visible",
      maxWidth: "100%",
      height: "auto",
      padding: 10
    }
  });

  const generateGridTreeMarkers = ({ boundaries, totalTrees, trees }) => {
    const treeMarkers = [];
    const bounds = new window.google.maps.LatLngBounds();
    const polygon = new window.google.maps.Polygon({ paths: boundaries });

    boundaries.forEach((latLng) =>
      bounds.extend(new window.google.maps.LatLng(latLng.lat, latLng.lng))
    );

    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const latSpan = northEast.lat() - southWest.lat();
    const lngSpan = northEast.lng() - southWest.lng();

    let cols = Math.ceil(Math.sqrt(totalTrees * 1.2));
    let rows = Math.ceil(Math.sqrt(totalTrees * 1.2));

    const latStep = latSpan / (rows + 1);
    const lngStep = lngSpan / (cols + 1);

    for (let i = 0; i < rows && treeMarkers.length < totalTrees; i++) {
      for (let j = 0; j < cols && treeMarkers.length < totalTrees; j++) {
        const lat = southWest.lat() + latStep * (i + 1);
        const lng = southWest.lng() + lngStep * (j + 1);
        const point = { lat, lng };

        if (
          window.google.maps.geometry.poly.containsLocation(
            new window.google.maps.LatLng(lat, lng),
            polygon
          )
        ) {
          treeMarkers.push(point);
        }
      }
    }

    return treeMarkers.slice(0, totalTrees)?.map((_, i) => ({
      ..._,
      treeUniqueId: trees?.[i]?.treeUniqueId,
    }));
  }

  const Setmap = ({ setmap }) => {
    let val = useMap();
    setmap.current = val;
    return <></>;
  };

  function panToWithPromise(map, position, targetZoom) {
    return new Promise((resolve) => {
      map.panTo(position);

      window.google.maps.event.addListenerOnce(map, "idle", () => {
        resolve();
      });
    });
  }

  // Effect to initialize marker clusterer when map is ready
  useEffect(() => {
    if (mapRef?.current) {
      const svg = window.btoa(`
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
          <defs></defs>
          <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
            <path d="M 88.441 80.946 H 1.558 c -0.829 0 -1.5 -0.672 -1.5 -1.5 s 0.671 -1.5 1.5 -1.5 h 86.883 c 0.828 0 1.5 0.672 1.5 1.5 S 89.27 80.946 88.441 80.946 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <circle cx="49.563" cy="56.113" r="14.113" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(28,165,111); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
            <path d="M 49.563 80.916 c -0.828 0 -1.5 -0.672 -1.5 -1.5 v -9.194 c 0 -0.828 0.672 -1.5 1.5 -1.5 s 1.5 0.672 1.5 1.5 v 9.194 C 51.063 80.244 50.391 80.916 49.563 80.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 49.563 71.722 c -8.609 0 -15.613 -7.004 -15.613 -15.612 c 0 -8.609 7.004 -15.613 15.613 -15.613 c 8.609 0 15.613 7.004 15.613 15.613 C 65.176 64.718 58.172 71.722 49.563 71.722 z M 49.563 43.496 c -6.955 0 -12.613 5.658 -12.613 12.613 c 0 6.954 5.658 12.612 12.613 12.612 c 6.955 0 12.613 -5.658 12.613 -12.612 C 62.176 49.154 56.518 43.496 49.563 43.496 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <circle cx="66.273" cy="31.083" r="16.413" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(153,213,59); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
            <path d="M 66.275 80.916 c -0.828 0 -1.5 -0.672 -1.5 -1.5 V 28.745 c 0 -0.829 0.672 -1.5 1.5 -1.5 s 1.5 0.671 1.5 1.5 v 50.671 C 67.775 80.244 67.104 80.916 66.275 80.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 66.275 48.998 c -9.877 0 -17.913 -8.035 -17.913 -17.912 s 8.036 -17.913 17.913 -17.913 s 17.912 8.036 17.912 17.913 S 76.152 48.998 66.275 48.998 z M 66.275 16.173 c -8.223 0 -14.913 6.69 -14.913 14.913 s 6.69 14.912 14.913 14.912 s 14.912 -6.689 14.912 -14.912 S 74.498 16.173 66.275 16.173 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <circle cx="74.38300000000001" cy="56.113" r="14.113" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(28,165,111); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
            <path d="M 74.387 80.916 c -0.828 0 -1.5 -0.672 -1.5 -1.5 V 54.097 c 0 -0.828 0.672 -1.5 1.5 -1.5 s 1.5 0.672 1.5 1.5 v 25.319 C 75.887 80.244 75.215 80.916 74.387 80.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 74.387 65.082 c -0.384 0 -0.768 -0.146 -1.061 -0.439 l -2.698 -2.698 c -0.586 -0.586 -0.586 -1.535 0 -2.121 s 1.535 -0.586 2.121 0 l 2.698 2.698 c 0.586 0.586 0.586 1.535 0 2.121 C 75.154 64.936 74.771 65.082 74.387 65.082 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 74.387 61.99 c -0.384 0 -0.768 -0.146 -1.061 -0.439 c -0.586 -0.586 -0.586 -1.535 0 -2.121 l 2.698 -2.698 c 0.586 -0.586 1.535 -0.586 2.121 0 s 0.586 1.535 0 2.121 l -2.698 2.698 C 75.154 61.844 74.771 61.99 74.387 61.99 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 74.387 71.722 c -8.609 0 -15.613 -7.004 -15.613 -15.612 c 0 -8.609 7.004 -15.613 15.613 -15.613 S 90 47.5 90 56.109 C 90 64.718 82.996 71.722 74.387 71.722 z M 74.387 43.496 c -6.955 0 -12.613 5.658 -12.613 12.613 c 0 6.954 5.658 12.612 12.613 12.612 S 87 63.063 87 56.109 C 87 49.154 81.342 43.496 74.387 43.496 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 34.927 49.041 c 0 11.925 -4.305 13.873 -9.615 13.873 s -9.615 -1.947 -9.615 -13.873 s 4.305 -36.791 9.615 -36.791 S 34.927 37.115 34.927 49.041 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(160,212,104); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 25.313 80.916 c -0.829 0 -1.5 -0.672 -1.5 -1.5 V 51.184 c 0 -0.828 0.671 -1.5 1.5 -1.5 s 1.5 0.672 1.5 1.5 v 28.232 C 26.813 80.244 26.141 80.916 25.313 80.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 25.313 64.413 c -7.791 0 -11.115 -4.598 -11.115 -15.372 c 0 -8.918 3.465 -38.291 11.115 -38.291 s 11.114 29.373 11.114 38.291 C 36.427 59.815 33.103 64.413 25.313 64.413 z M 25.313 13.75 c -2.938 0 -8.115 20.402 -8.115 35.291 c 0 11.417 4.009 12.372 8.115 12.372 s 8.114 -0.955 8.114 -12.372 C 33.427 34.152 28.25 13.75 25.313 13.75 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <circle cx="15.613" cy="56.113" r="14.113" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(28,165,111); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
            <path d="M 15.613 80.916 c -0.829 0 -1.5 -0.672 -1.5 -1.5 V 54.097 c 0 -0.828 0.671 -1.5 1.5 -1.5 s 1.5 0.672 1.5 1.5 v 25.319 C 17.113 80.244 16.442 80.916 15.613 80.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 15.613 65.082 c -0.384 0 -0.768 -0.146 -1.061 -0.439 c -0.586 -0.586 -0.586 -1.535 0 -2.121 l 2.698 -2.698 c 0.585 -0.586 1.535 -0.586 2.121 0 s 0.586 1.535 0 2.121 l -2.698 2.698 C 16.381 64.936 15.997 65.082 15.613 65.082 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 15.613 61.99 c -0.384 0 -0.768 -0.146 -1.061 -0.439 l -2.698 -2.698 c -0.586 -0.586 -0.586 -1.535 0 -2.121 s 1.535 -0.586 2.121 0 l 2.698 2.698 c 0.586 0.586 0.586 1.535 0 2.121 C 16.381 61.844 15.997 61.99 15.613 61.99 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 15.613 71.722 C 7.004 71.722 0 64.718 0 56.109 C 0 47.5 7.004 40.496 15.613 40.496 c 8.609 0 15.613 7.004 15.613 15.613 C 31.226 64.718 24.222 71.722 15.613 71.722 z M 15.613 43.496 C 8.658 43.496 3 49.154 3 56.109 c 0 6.954 5.658 12.612 12.613 12.612 c 6.955 0 12.613 -5.658 12.613 -12.612 C 28.226 49.154 22.568 43.496 15.613 43.496 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 38.041 14.581 c -5.394 6.591 -12.432 22.135 -12.432 36.4 c 0 6.866 5.566 12.432 12.432 12.432 s 12.432 -5.566 12.432 -12.432 C 50.473 36.716 43.435 21.173 38.041 14.581 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(153,213,59); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 38.041 80.916 c -0.829 0 -1.5 -0.672 -1.5 -1.5 V 40.764 c 0 -0.829 0.671 -1.5 1.5 -1.5 s 1.5 0.671 1.5 1.5 v 38.652 C 39.541 80.244 38.87 80.916 38.041 80.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 38.041 58.698 c -0.384 0 -0.768 -0.146 -1.061 -0.439 l -4.675 -4.675 c -0.586 -0.586 -0.586 -1.535 0 -2.121 s 1.535 -0.586 2.121 0 l 4.675 4.675 c 0.586 0.586 0.586 1.535 0 2.121 C 38.809 58.552 38.425 58.698 38.041 58.698 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 38.041 53.341 c -0.384 0 -0.768 -0.146 -1.061 -0.439 c -0.586 -0.586 -0.586 -1.535 0 -2.121 l 4.674 -4.675 c 0.585 -0.586 1.535 -0.586 2.121 0 s 0.586 1.535 0 2.121 l -4.674 4.675 C 38.809 53.194 38.425 53.341 38.041 53.341 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 38.041 64.914 c -7.683 0 -13.933 -6.25 -13.933 -13.933 c 0 -14.999 7.469 -30.87 12.772 -37.35 c 0.285 -0.348 0.711 -0.55 1.161 -0.55 l 0 0 c 0.45 0 0.876 0.202 1.161 0.55 c 5.303 6.48 12.771 22.352 12.771 37.35 C 51.973 58.664 45.723 64.914 38.041 64.914 z M 38.041 17.059 c -4.882 6.862 -10.933 20.732 -10.933 33.922 c 0 6.028 4.904 10.933 10.933 10.933 c 6.028 0 10.932 -4.904 10.932 -10.933 C 48.973 37.792 42.922 23.921 38.041 17.059 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 58.471 62.42 L 58.471 62.42 c -6.142 0 -11.121 -4.979 -11.121 -11.121 V 21.675 c 0 -6.142 4.979 -11.121 11.121 -11.121 h 0 c 6.142 0 11.121 4.979 11.121 11.121 v 29.624 C 69.592 57.441 64.613 62.42 58.471 62.42 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(160,212,104); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 58.471 80.916 c -0.828 0 -1.5 -0.672 -1.5 -1.5 v -44.22 c 0 -0.829 0.672 -1.5 1.5 -1.5 s 1.5 0.671 1.5 1.5 v 44.22 C 59.971 80.244 59.299 80.916 58.471 80.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 58.471 54.151 c -0.384 0 -0.768 -0.146 -1.061 -0.439 c -0.586 -0.586 -0.586 -1.535 0 -2.121 l 4.966 -4.965 c 0.586 -0.586 1.535 -0.586 2.121 0 s 0.586 1.535 0 2.121 l -4.966 4.965 C 59.238 54.005 58.854 54.151 58.471 54.151 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 58.471 48.462 c -0.384 0 -0.768 -0.146 -1.061 -0.439 l -4.965 -4.965 c -0.586 -0.585 -0.586 -1.536 0 -2.121 c 0.586 -0.586 1.535 -0.586 2.121 0 l 4.965 4.965 c 0.586 0.586 0.586 1.535 0 2.121 C 59.238 48.315 58.854 48.462 58.471 48.462 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path d="M 58.471 63.92 c -6.959 0 -12.62 -5.662 -12.62 -12.621 V 21.675 c 0 -6.959 5.661 -12.621 12.62 -12.621 s 12.621 5.662 12.621 12.621 v 29.624 C 71.092 58.258 65.43 63.92 58.471 63.92 z M 58.471 12.054 c -5.305 0 -9.62 4.316 -9.62 9.621 v 29.624 c 0 5.305 4.315 9.621 9.62 9.621 s 9.621 -4.316 9.621 -9.621 V 21.675 C 68.092 16.37 63.775 12.054 58.471 12.054 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          </g>
        </svg>`);

      const renderer = {
        render: ({ count, position }) =>
          new window.google.maps.Marker({

            label: { text: String(count), className: classes?.root },
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(60, 60),
            },
            position,
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
          }),
      };

      markerClustererRef.current = new MarkerClusterer({
        map: mapRef?.current,
        renderer,
      });
    }

    return () => {
      markerClustererRef?.current?.clearMarkers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef?.current]);

  // Effect to update markers in the clusterer based on forestList
  useEffect(() => {
    if (markerClustererRef?.current) {
      const markers = [];

      forestList.forEach((val) => {
        if (zoom >= 16) {
          val.clusters.forEach((cluster) => {
            const treeMarkers = generateGridTreeMarkers({
              boundaries: cluster.boundaries,
              totalTrees: cluster?.treesCount,
              trees: cluster?.clusterTrees,
            }).map((point) => {
              const marker = new window.google.maps.Marker({
                position: point,
                icon: {
                  url: "/tree.png",
                  scaledSize: { width: 50, height: 50 },
                },
              });
              marker.addListener("click", () => handleTreeClick(point));
              return marker;
            });
            markers.push(...treeMarkers);
          });
        }
      });

      // Clear previous markers and add new ones
      markerClustererRef?.current?.clearMarkers();
      markerClustererRef?.current?.addMarkers(markers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forestList, zoom]);

  useEffect(() => {
    if (mapRef?.current) {
      if (searchValue) {
        panToWithPromise(mapRef?.current, searchValue.value, 15).then(() => {
          setZoom(isMyForest ? 10 : 15);
        });
      } else if (initialCenter) {
        panToWithPromise(mapRef?.current, initialCenter, 15).then(() => {
          setZoom(isMyForest ? 10 : 15);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, initialCenter, isInitialTriggered]);

  const handleMapCenterChanged = (event) => {
    setcurrentpos(event?.detail?.center)
  }

  const handleMapIdle = () => {
    if (!isInitialTriggered) {
      setIsInitialTriggered(true)
    }
    if (isInitialTriggered) {
      setSnackBarState({ vertical: 'top', horizontal: 'center', open: false });
    }
  }

  const handleMapZoomChanged = (event) => {
    if (isInitialTriggered) {
      setSnackBarState({ vertical: 'top', horizontal: 'center', open: true });
    }
    setZoom(event?.detail?.zoom)
  }

  const handleMapTileLoaded = () => {
    if (isInitialTriggered) {
      setSnackBarState({ vertical: 'top', horizontal: 'center', open: false });
    }
  }

  const handleMapDblClick = () => {
    if (isInitialTriggered) {
      setSnackBarState({ vertical: 'top', horizontal: 'center', open: true });
    }
  }

  return (
    <div>
      <Stas data={forestDetails} />
      {forestList?.length > 0
        ? (
          <APIProvider apiKey={google_api_key}>
            <Map
              reuseMaps={true}
              style={{ width: "100vw", height: size?.height - 64 }}
              defaultCenter={currentpos}
              defaultZoom={3}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
              onZoomChanged={handleMapZoomChanged}
              zoom={zoom}
              onTilesLoaded={handleMapTileLoaded}
              onIdle={handleMapIdle}
              onCenterChanged={handleMapCenterChanged}
              onDblclick={handleMapDblClick}
              scrollwheel={true}
              minZoom={3}
              maxZoom={25}>
              <Setmap setmap={mapRef} />
              {isMyForest
                ? forestList?.map((val, i) => (
                  <div key={i}>
                    {val?.clusters?.[0]?.clusterTrees?.map?.(_ => (
                      <Marker
                        position={{ lat: _?.forestTreeGeoLat, lng: _?.forestTreeGeoLong }}
                        icon={{
                          url: "/tree.png",
                          scaledSize: { width: 50, height: 50 },
                        }}
                        onClick={() => handleTreeClick({
                          lat: _?.forestTreeGeoLat,
                          lng: _?.forestTreeGeoLong,
                          treeUniqueId: _?.treeUniqueId,
                        })}
                      />
                    ))}
                  </div>
                ))
                : forestList?.map((val, i) => (
                  <div key={i}>
                    {zoom < 16
                      ? (
                        <>
                          <Marker
                            position={val?.center}
                            icon={{
                              url: val?.forestLogo,
                              scaledSize: {
                                width: 90,
                                height: 90,
                              },
                            }}
                            onClick={() => handleForestClick(val)}
                          />
                          <Polygon
                            path={val?.boundaries}
                            strokeColor={"#7DBB5D"}
                            strokeOpacity={1}
                            strokeWeight={3}
                            fillColor={"#7DBB5D"}
                          />
                        </>
                      )
                      : (
                        val?.clusters?.map((cluster, index) => (
                          <Polygon
                            key={index}
                            path={cluster?.boundaries}
                            strokeColor={"#7DBB5D"}
                            strokeOpacity={1}
                            strokeWeight={3}
                            fillColor={"#7DBB5D"}
                          />
                        ))
                      )
                    }
                  </div>
                ))}
            </Map>
          </APIProvider>
        )
        : (
          <CustomSkeleton component="div" variant="rectangular">
            <img src={Logo} alt={"logo"} />
          </CustomSkeleton>
        )}
      <MapActionButton
        size={size}
        handleOpenStoreList={handleOpenStoreList}
        handleOpenPlantSaplingForm={handleOpenPlantSaplingForm}
        handleCameraOpen={handleCameraOpen}
        handlePlantSapling={handlePlantSapling} />
      <Snackbar
        anchorOrigin={{ vertical: snackBarState?.vertical, horizontal: snackBarState?.horizontal }}
        open={snackBarState?.open}
        onClose={() => setSnackBarState({ vertical: 'top', horizontal: 'center', open: false })}>
        <Alert
          icon={false}
          action={false}
          onClose={() => setSnackBarState({ vertical: 'top', horizontal: 'center', open: false })}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}>
          {"Loading trees in forest..."}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomForestMap;